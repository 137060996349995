import { BranchEffectivePolicy } from 'services/location/locationTypes';
import { parseUrn } from 'utils/urnUtils';
import { ChargeLine, Charges } from 'services/booking/bookingTypes';
import { formatCurrency } from 'utils/currencyUtils';
import { DOUBLE_DASH, EMPTY_VALUE } from 'utils/constants';

/**
 * reorders the policies based on the following order
 * 1. Renter Requirements (REQ1, REQ2, etc.)
 * 2. Mandatory Policies in alphabetical order
 * 3. All other policies in alphabetical order
 **/
export const reorderPolices = (policies: BranchEffectivePolicy[] | undefined): BranchEffectivePolicy[] | undefined => {
  if (!policies?.length) {
    return undefined;
  }
  const renterRequired = policies?.filter((policy) => policy.name.includes('REQ'));

  const mandatory = policies
    ?.filter((policy) => policy.mandatoryReviewNeeded)
    .sort((a, b) => a.localizedName.localeCompare(b.localizedName));

  const remaining = policies
    ?.filter((policy) => !policy.name.includes('REQ') && policy.mandatoryReviewNeeded === false)
    .sort((a, b) => a.localizedName.localeCompare(b.localizedName));

  return [...renterRequired, ...mandatory, ...remaining];
};

export enum ChargeCategories {
  CPN = 'CPN',
  SVH = 'SVH',
  SVI = 'SVI',
  ALW = 'ALW',
  INT = 'INT',
  RNT = 'RNT',
  BAA = 'BAA',
  SBW = 'SBW',
  DIS = 'DIS',
  CPB = 'CPB',
  CPD = 'CPD',
  FVH = 'FVH',
  SPE = 'SPE',
  INS = 'INS',
  CDR = 'CDR',
  FDP = 'FDP',
  CDU = 'CDU',
  FSC = 'FSC',
  FUL = 'FUL',
  QSP = 'QSP',
  MIS = 'MIS',
  AFH = 'AFH',
  VAT = 'VAT',
  TAX = 'TAX',
  LDF = 'LDF',
  ADR = 'ADR',
  ONW = 'ONW',
  YRT = 'YRT',
  DAC = 'DAC',
  SUR = 'SUR',
}

const vehicleCategories = [
  ChargeCategories.CPN,
  ChargeCategories.SVH,
  ChargeCategories.SVI,
  ChargeCategories.ALW,
  ChargeCategories.INT,
  ChargeCategories.RNT,
  ChargeCategories.BAA,
  ChargeCategories.SBW,
  ChargeCategories.DIS,
  ChargeCategories.CPB,
  ChargeCategories.CPD,
  ChargeCategories.FVH,
];

const extrasCategories = [
  ChargeCategories.SPE,
  ChargeCategories.INS,
  ChargeCategories.CDR,
  ChargeCategories.FDP,
  ChargeCategories.CDU,
  ChargeCategories.FSC,
  ChargeCategories.FUL,
  ChargeCategories.QSP,
  ChargeCategories.MIS,
];

const taxesAndFeesCategories = [
  ChargeCategories.AFH,
  ChargeCategories.VAT,
  ChargeCategories.TAX,
  ChargeCategories.LDF,
  ChargeCategories.ADR,
  ChargeCategories.ONW,
  ChargeCategories.YRT,
  ChargeCategories.DAC,
  ChargeCategories.SUR,
];

export const categorizeCharges = (charges: Charges, locale: string) => {
  const currencyCode = charges.totalCharges?.currencyCode ?? EMPTY_VALUE;
  const vehicleCharges: number[] = [];
  const extrasCharges: number[] = [];
  const taxAndFeesCharges: number[] = [];
  charges.chargeLine?.forEach((charge: ChargeLine) => {
    const category = parseUrn(charge.chargeCategory);
    if (vehicleCategories.includes(category as ChargeCategories) && typeof charge.amount?.amount === 'number') {
      vehicleCharges.push(charge.amount?.amount);
    } else if (extrasCategories.includes(category as ChargeCategories) && typeof charge.amount?.amount === 'number') {
      extrasCharges.push(charge.amount?.amount);
    } else if (
      taxesAndFeesCategories.includes(category as ChargeCategories) &&
      typeof charge.amount?.amount === 'number'
    ) {
      taxAndFeesCharges.push(charge.amount?.amount);
    }
  });

  const vehicleChargesTotal = getCategoryTotalCharges(vehicleCharges, currencyCode, locale);
  const extrasChargesTotal = getCategoryTotalCharges(extrasCharges, currencyCode, locale);
  const taxAndFeesChargesTotal = getCategoryTotalCharges(taxAndFeesCharges, currencyCode, locale);
  return { vehicleChargesTotal, extrasChargesTotal, taxAndFeesChargesTotal };
};

const getCategoryTotalCharges = (chargesArray: number[], currencyCode: string, locale: string) => {
  if (chargesArray.length) {
    const initialValue = 0;
    const sum = chargesArray.reduce((previousTotal, currentValue) => {
      return previousTotal + currentValue;
    }, initialValue);
    return formatCurrency(sum, currencyCode ?? EMPTY_VALUE, locale);
  } else return DOUBLE_DASH;
};

export const getTotalCharges = (locale: string, amount?: number | string, currencyCode?: string) => {
  return typeof amount === 'number' ? formatCurrency(amount, currencyCode ?? EMPTY_VALUE, locale) : DOUBLE_DASH;
};
