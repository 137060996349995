import { FC, MutableRefObject, useCallback, useState } from 'react';
import { DriverFormFields, DriverFormValues, DriverType } from 'components/flexFlow/driver/driverForm/DriverFormTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { DriverSection } from 'components/flexFlow/driver/driverForm/formSection/DriverSection';
import { LicenseSection } from 'components/flexFlow/driver/driverForm/formSection/LicenseSection';
import { ContactSection } from 'components/flexFlow/driver/driverForm/formSection/ContactSection';
import { MarginWrapper } from 'components/shared/ui/styles/Global.styles';
import { DividerWithFullWidth } from 'components/shared/ui/styles/Divider.styles';
import { ehiTheme } from '@ehi/ui';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import {
  driverFormInitialValues,
  driverFormValidationSchema,
  isValidFullProfile,
} from 'components/flexFlow/driver/driverForm/driverFormUtils';
import {
  createDriverProfile,
  modifyContactInformation,
  modifyDriverProfile,
} from 'services/renter/driverProfile/driverProfileService';
import {
  transformToCreateDriverProfileRequest,
  transformToCreateTransactionalProfileRequest,
  transformToModifyContactInfoRequest,
  transformToModifyDriverProfileRequest,
} from 'components/shared/uiModels/driver/driverTransformer';
import { safelyCatchError } from 'utils/errorUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import {
  createTransactionalProfile,
  getTransactionalProfile,
  updateTransactionalProfile,
} from 'services/renter/transactionalProfile/transactionalProfileService';
import { DriverData, RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { Renter } from 'services/booking/bookingTypes';
import { associateRenterToReservationEditor, updateAdditionalDrivers } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectDriverProfileRenter,
} from 'redux/selectors/bookingEditor';
import { useTranslations } from 'components/shared/i18n';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { DriverProfile } from 'services/renter/driverProfile/driverProfileTypes';
import { TransactionalProfileResponseContent } from 'services/renter/transactionalProfile/transactionalProfileTypes';
import { Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { StyledDriverBanner } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { parseUrn } from 'utils/urnUtils';

export type CreateDriverFormProps = {
  onClose: (driverModified: boolean) => void;
  formRef?: MutableRefObject<{ handleSubmit: () => Promise<void> } | null>;
  driver?: DriverData;
};

export const DriverForm: FC<CreateDriverFormProps> = ({ onClose, formRef, driver }) => {
  const { t } = useTranslations();
  const appConfig = getAppConfigCache();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(false);

  const resolver = useYupValidationResolver(driverFormValidationSchema(t));
  const formMethods = useForm<DriverFormValues>({
    resolver: resolver,
    defaultValues: driverFormInitialValues(driver),
  });

  const invokeAlert = useCallback(
    async (description: string | JSX.Element) => {
      setLoading(false);
      await showAlert({
        variant: 'error',
        description: description,
      });
    },
    [showAlert]
  );

  const handleError = useCallback(
    async (errors: ResponseMessage[] | undefined, message: string) => {
      if (errors) {
        await invokeAlert(`${t(message)}: ${errors?.[0]?.localizedMessage || ''}`);
      } else {
        setLoading(false);
        onClose(false);
      }
    },
    [invokeAlert, onClose, t]
  );

  const handleUpdateEditor = useCallback(
    async (driverProfile: DriverProfile, isTransactionProfile?: boolean) => {
      if (!driverProfile.urn) {
        await invokeAlert(t('driver.addDriverError'));
      } else {
        if (!driverProfileRenter) {
          const requestBody = {
            type: isTransactionProfile ? RenterTypes.TransactionalProfile : RenterTypes.DriverProfile,
            profile: driverProfile.urn,
          } as Renter;

          const { errors } = await updateAndRefresh(() =>
            associateRenterToReservationEditor(bookingEditorId, requestBody)
          );
          await handleError(errors, 'driver.addDriverError');
        } else {
          const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];

          const requestBody = [
            ...currentAdditionalDrivers,
            {
              name: { given: driverProfile.name?.givenName, surname: driverProfile.name?.surname ?? '' },
              profile: driverProfile.urn,
            },
          ];
          const { errors } = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, requestBody));
          await handleError(errors, 'driver.addAdditionalDriverError');
        }
      }
    },
    [bookingEditorId, driverProfileRenter, invokeAlert, t, updateAndRefresh, additionalDrivers, handleError]
  );

  const handleCreatingProfile = useCallback(
    async (values: DriverFormValues) => {
      setLoading(true);
      try {
        if (isValidFullProfile(values)) {
          const driverProfile: DriverProfile = await createDriverProfile(
            transformToCreateDriverProfileRequest(values, appConfig?.defaultEhiDatabase ?? '')
          );
          await handleUpdateEditor(driverProfile);
        } else {
          const transactionalProfile: TransactionalProfileResponseContent = await createTransactionalProfile(
            transformToCreateTransactionalProfileRequest(values, appConfig?.defaultEhiDatabase ?? '')
          );
          const driverProfile: DriverProfile = {
            name: {
              surname: transactionalProfile.personalInformation?.name?.surname ?? '',
              givenName: transactionalProfile.personalInformation?.name?.givenName ?? '',
            },
            urn: transactionalProfile.urn,
          };

          await handleUpdateEditor(driverProfile, true);
        }
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        if (ehiErrorsResponse?.errors) {
          await showAlert({ responseMessages: ehiErrorsResponse?.errors });
        }
        setLoading(false);
      }
    },
    [appConfig?.defaultEhiDatabase, handleUpdateEditor, showAlert]
  );

  const handleModifyingProfile = useCallback(
    async (values: DriverFormValues) => {
      if (Object.keys(formMethods.formState.dirtyFields).length) {
        setLoading(true);
        try {
          if (values[DriverFormFields.DriverType] === DriverType.LOYALTY_DRIVER_PROFILE) {
            await modifyContactInformation(
              parseUrn(driverProfileRenter?.profile),
              transformToModifyContactInfoRequest(values, appConfig?.defaultEhiDatabase ?? '')
            );
            onClose(true);
          } else if (
            values[DriverFormFields.DriverType] === DriverType.DRIVER_PROFILE ||
            values[DriverFormFields.DriverType] === DriverType.DRIVER_PROFILE_DNR
          ) {
            await modifyDriverProfile(
              parseUrn(driverProfileRenter?.profile),
              transformToModifyDriverProfileRequest(values, appConfig?.defaultEhiDatabase ?? '')
            );
            onClose(true);
          } else if (driverProfileRenter?.profile) {
            await getTransactionalProfile(driverProfileRenter.profile)
              .then(async (profile) => {
                await updateTransactionalProfile(
                  parseUrn(profile.uuid),
                  transformToCreateTransactionalProfileRequest(values, appConfig?.defaultEhiDatabase ?? ''),
                  parseUrn(profile.lockContextUrn)
                );
                onClose(true);
              })
              .catch(async (error) => {
                const ehiErrorsResponse = safelyCatchError(error);
                if (ehiErrorsResponse?.errors) {
                  await showAlert({ responseMessages: ehiErrorsResponse?.errors });
                }
              });
          }
        } catch (error) {
          const ehiErrorsResponse = safelyCatchError(error);
          if (ehiErrorsResponse?.errors) {
            await handleError(ehiErrorsResponse?.errors, 'driver.updateDriverError');
          }
        } finally {
          setLoading(false);
        }
      } else {
        onClose(false);
      }
    },
    [
      appConfig?.defaultEhiDatabase,
      driverProfileRenter?.profile,
      formMethods.formState.dirtyFields,
      handleError,
      onClose,
      showAlert,
    ]
  );

  if (formRef) {
    formRef.current = {
      handleSubmit: !driver
        ? formMethods.handleSubmit(handleCreatingProfile)
        : formMethods.handleSubmit(handleModifyingProfile),
    };
  }

  const getBannerMessage = (driverType: DriverType | undefined) => {
    if (driverType === DriverType.DRIVER_PROFILE || driverType === DriverType.DRIVER_PROFILE_DNR) {
      return t('driver.driverProfileBanner');
    } else if (driverType === DriverType.LOYALTY_DRIVER_PROFILE) {
      return t('driver.loyaltyDriverBanner');
    }
    return undefined;
  };

  return (
    <MarginWrapper data-testid={'createDriverForm'}>
      <FormProvider {...formMethods}>
        {getBannerMessage(formMethods.getValues(DriverFormFields.DriverType)) && (
          <StyledDriverBanner data-testid={'modifyDriverBanner'}>
            <Alert
              severity='info'
              sx={{
                border: `1px solid #6A3E9D80`,
                display: 'flex',
                alignItems: 'center',
              }}
              icon={<InfoIcon />}>
              {getBannerMessage(formMethods.getValues(DriverFormFields.DriverType))}
            </Alert>
          </StyledDriverBanner>
        )}
        <DriverSection />
        <DividerWithFullWidth style={{ borderColor: ehiTheme.palette.divider }} />
        <LicenseSection />
        <DividerWithFullWidth style={{ borderColor: ehiTheme.palette.divider }} />
        <ContactSection />
      </FormProvider>
      <ProgressOverlay inProgress={loading} />
    </MarginWrapper>
  );
};
