import { useCallback } from 'react';
import { BusinessPayer, EditorBusinessPayer } from 'services/booking/bookingTypes';
import { generateUrnForBillingAccount } from 'utils/urnUtils';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { updateBusinessPayers } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import {
  BillingAccountType,
  BillToFormFields,
} from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { EhiErrors, HttpStatusCode } from 'services/types/EhiErrorsTypes';
import { useTranslations } from 'components/shared/i18n';
import { useFormContext } from 'react-hook-form';
import { BillingAccountErrorCodes } from 'utils/errorUtils';
import { useEditBillToContext } from 'context/editBillTo/EditBillToContext';
import { useAlert } from 'components/shared/alert/AlertContext';

export type AddOrModifyBillToProps = {
  billingNumber: string;
  accountNumber: string;
};

export type useRateAndBillingHookReturn = {
  addOrModifyBillTo: (props: AddOrModifyBillToProps) => Promise<ServiceResultType>;
  checkBillingAccountErrors: (billingAccountErrors: EhiErrors) => void;
};

export const useBillTo = (): useRateAndBillingHookReturn => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const { showAlert } = useAlert();
  const { isAccountNumberFieldHidden } = useEditBillToContext();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { setError } = useFormContext();

  const addOrModifyBillTo = useCallback(
    async ({ billingNumber, accountNumber }: AddOrModifyBillToProps): Promise<ServiceResultType> => {
      const request: EditorBusinessPayer[] = [
        {
          type: BillingAccountType.BILLING_ACCOUNT,
          billingAccount: generateUrnForBillingAccount(
            accountNumber.toUpperCase(),
            billingNumber.toUpperCase(),
            defaultEhiDatabase
          ),
        } as BusinessPayer,
      ];

      return updateAndRefresh(async () => updateBusinessPayers(bookingEditorId, request));
    },
    [bookingEditorId, defaultEhiDatabase, updateAndRefresh]
  );

  const checkBillingAccountErrors = useCallback(
    async (billingAccountErrors: EhiErrors) => {
      if (billingAccountErrors.status === HttpStatusCode.NotFound) {
        isAccountNumberFieldHidden
          ? await showAlert({
              description: t('rateAndBilling.billTo.noBillingAccount'),
            })
          : setError(BillToFormFields.AccountNumber, { message: t('rateAndBilling.billTo.noBillingAccount') });
        return;
      } else {
        const errorCodes = billingAccountErrors.errors?.map((message) => message.code);
        if (errorCodes?.includes(BillingAccountErrorCodes.InvalidAccount)) {
          setError(BillToFormFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
          return;
        } else if (
          errorCodes?.includes(BillingAccountErrorCodes.BillingNumberIsRequired) ||
          errorCodes?.includes(BillingAccountErrorCodes.BillingNumberAlwaysRequired) ||
          errorCodes?.includes(BillingAccountErrorCodes.MoreThanOneBillingAccountNumber)
        ) {
          // Error codes APE00197, APE00027, and APE00185 all indicate that the Billing Account Exists is “True” and BNR flag is ‘Yes’.
          // This will allow user to manually enter billing number.
          return;
        } else {
          setError(BillToFormFields.AccountNumber, { message: billingAccountErrors?.errors?.[0].localizedMessage });
          return;
        }
      }
    },
    [isAccountNumberFieldHidden, setError, showAlert, t]
  );

  return {
    addOrModifyBillTo,
    checkBillingAccountErrors,
  };
};
