import React, { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  BranchLookupFields,
  locationOptionType,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { useTranslations } from 'components/shared/i18n';
import { OperationalLocationType } from 'services/location/locationReferenceTypes';
import {
  StyledLocationsFormControl,
  StyledSelect,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { ehiTheme } from '@ehi/ui';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { StyledInputLabel } from 'components/flexFlow/whenAndWhere/branchLookup/brandFilter/BrandFilterDropDown.styles';
import {
  AllToggleText,
  ButtonContainer,
  EhiButtonReset,
  HeaderContainer,
  HeaderTitle,
  SubHeaderContainer,
} from 'components/shared/forms/Forms.styles';
import { ListSubheader } from '@mui/material';

export interface LocationTypesDropDownProps {
  locationTypeOptions: locationOptionType[];
}

export const LocationTypesDropDown: FC<LocationTypesDropDownProps> = ({ locationTypeOptions }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslations();
  const { watch, control, setValue } = useFormContext();
  const selectedTypes = watch(BranchLookupFields.LocationTypes);
  const allTypesSelected = selectedTypes.length === locationTypeOptions.length;

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setIsOpen(false);
  }, []);

  const toggleAllTypes = useCallback(() => {
    const newSelectedTypes = allTypesSelected ? [] : locationTypeOptions;
    setValue(BranchLookupFields.LocationTypes, newSelectedTypes);
  }, [allTypesSelected, locationTypeOptions, setValue]);

  const handleClear = useCallback(() => {
    setValue(BranchLookupFields.LocationTypes, locationTypeOptions);
  }, [setValue, locationTypeOptions]);

  const renderSelectedTypes = useCallback(() => {
    if (allTypesSelected) {
      return t('location.allLocationTypes');
    } else if (selectedTypes?.length === 1) {
      return selectedTypes[0].name;
    } else if (!selectedTypes?.length) {
      return t('location.locationTypes');
    } else {
      return `${selectedTypes[0].name?.substring(0, 15)}, +${selectedTypes.length - 1}`;
    }
  }, [t, selectedTypes, allTypesSelected]);

  return (
    <StyledLocationsFormControl variant='outlined'>
      <StyledInputLabel>{t('location.locationTypes')}</StyledInputLabel>
      <Controller
        name={BranchLookupFields.LocationTypes}
        control={control}
        render={() => (
          <StyledSelect
            multiple
            fullWidth
            value={locationTypeOptions?.map((item: any) => item.urn ?? '')}
            renderValue={renderSelectedTypes}
            data-testid={'locationTypeFilter'}
            onClick={handleOpen}
            open={open}
            onClose={handleClose}
            MenuProps={{
              PaperProps: {
                style: {
                  display: 'none',
                },
              },
            }}
          />
        )}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: anchorEl ? `${anchorEl.clientWidth * 1.5}px` : 'auto',
              maxHeight: window.innerHeight / 2,
              ...(window.innerWidth <= 800 && {
                minWidth: '25%',
              }),
            },
            sx: {
              transition: 'none !important',
            },
          },
          root: { sx: { '.MuiList-root': { padding: 0 } } },
        }}>
        <ListSubheader>
          <HeaderContainer>
            <HeaderTitle>{t('location.locationTypes')}</HeaderTitle>
            <IconButton size='small' onClick={handleClose}>
              <CloseIcon color={'secondary'} data-testid='close-dropdown' />
            </IconButton>
          </HeaderContainer>
          <HeaderContainer>
            <AllToggleText>{t('location.allLocationTypes')}</AllToggleText>
            <Switch checked={allTypesSelected} onChange={toggleAllTypes} data-testid='allTypesSwitch' />
          </HeaderContainer>
        </ListSubheader>
        {locationTypeOptions?.map((item: any) => (
          <MenuItem
            key={item.code}
            data-testid={`location-type-${item.code}`}
            value={item.name}
            sx={{ paddingLeft: `${ehiTheme.spacing(0.5)}` }}
            onClick={() => {
              const newSelectedTypes = selectedTypes?.some(
                (selected: OperationalLocationType) => selected.code === item.code
              )
                ? selectedTypes?.filter((selected: OperationalLocationType) => selected.code !== item.code)
                : [...selectedTypes, item];
              setValue(BranchLookupFields.LocationTypes, newSelectedTypes);
            }}>
            <Checkbox
              checked={selectedTypes?.some((selected: OperationalLocationType) => selected.code === item.code)}
            />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
        <SubHeaderContainer style={{ bottom: 0 }}>
          <ButtonContainer>
            <EhiButtonReset
              onClick={handleClear}
              disabled={allTypesSelected}
              sx={{ margin: `${ehiTheme.spacing(0.5, 0.5, 0, 0)}` }}>
              {t('common.clear')}
            </EhiButtonReset>
          </ButtonContainer>
        </SubHeaderContainer>
      </Menu>
    </StyledLocationsFormControl>
  );
};
