import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Button from '@mui/material/Button';
import { FunctionComponent, memo, useContext } from 'react';
import { CardContainer, FlexEndItem, FlexStartItem } from 'components/shared/ui/card/CardNavigation.styles';
import { ResSummaryDrawerContext } from 'context/resSummary/ResSummaryDrawerContext';
import { CardNavigationProps } from './CardTypes';
import { useTranslations } from 'components/shared/i18n';

export const CardNavigation: FunctionComponent<CardNavigationProps> = memo(
  ({ onPrevious, onNext, showExitToRentalSummary, hideOnNext, customNextButton, isReviewPage }) => {
    const { openDrawer, setOpenDrawer } = useContext(ResSummaryDrawerContext);
    const { t } = useTranslations();

    return (
      <>
        <CardContainer sx={{ borderRadius: 10 }}>
          <FlexStartItem>
            {showExitToRentalSummary && (
              <Button startIcon={<ListAltOutlinedIcon />} onClick={() => setOpenDrawer(!openDrawer)}>
                {t('cardNav.reservationSummary')}
              </Button>
            )}
            {isReviewPage && onPrevious && (
              <Button startIcon={<ArrowBackIos />} onClick={onPrevious}>
                {t('cardNav.previous')}
              </Button>
            )}
          </FlexStartItem>
          <FlexEndItem>
            {onPrevious && !isReviewPage && (
              <Button startIcon={<ArrowBackIos />} onClick={onPrevious}>
                {t('cardNav.previous')}
              </Button>
            )}
            {onNext && !customNextButton && (
              <Button
                endIcon={<ArrowForwardIos />}
                onClick={onNext}
                style={{ visibility: !hideOnNext ? 'visible' : 'hidden' }}>
                {t('cardNav.next')}
              </Button>
            )}
            {onNext && customNextButton && customNextButton({ onClick: onNext })}
          </FlexEndItem>
        </CardContainer>
      </>
    );
  }
);
