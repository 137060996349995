import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { EditBillToContextProps } from 'context/editBillTo/EditBillToContextTypes';
import { AccountSearchValues } from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearchTypes';
import { BusinessAccountSearchResult } from 'services/businessAccount/businessAccountTypes';

export const EditBillToContext = createContext<EditBillToContextProps>({
  isAccountNumberFieldHidden: false,
  setIsAccountNumberFieldHidden: () => undefined,
  previousSearchResults: undefined,
  previousSearchCriteria: undefined,
  storePreviousSearchHistory: () => undefined,
});

export const useEditBillToContext = () => {
  const context = useContext(EditBillToContext);

  if (!context) {
    throw Error('EditBillToContext is not initialized');
  }

  return context;
};

export const EditBillToProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isAccountNumberFieldHidden, setIsAccountNumberFieldHidden] = useState(false);
  const [previousSearchResults, setPreviousSearchResults] = useState<BusinessAccountSearchResult[] | undefined>(
    undefined
  );
  const [previousSearchCriteria, setPreviousSearchCriteria] = useState<AccountSearchValues | undefined>(undefined);

  const storePreviousSearchHistory = (
    searchCriteria?: AccountSearchValues,
    searchResults?: BusinessAccountSearchResult[]
  ) => {
    setPreviousSearchResults(searchResults);
    setPreviousSearchCriteria(searchCriteria);
  };

  return (
    <EditBillToContext.Provider
      value={{
        isAccountNumberFieldHidden,
        setIsAccountNumberFieldHidden,
        previousSearchResults,
        previousSearchCriteria,
        storePreviousSearchHistory,
      }}>
      {children}
    </EditBillToContext.Provider>
  );
};
