import { ReactNode, useState } from 'react';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { prefetchAppConfig } from 'services/appConfig/appConfigService';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { prefetchVersion } from 'services/version/versionService';

type AppConfigGuardProps = {
  children: ReactNode;
};
export const AppConfigGuard = ({ children }: AppConfigGuardProps) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);

  useEffectOnlyOnce(async () => {
    // Load app config
    try {
      await prefetchAppConfig();
      await prefetchVersion();
      setLoadingState(LoadingState.SUCCESS);
    } catch (error) {
      setLoadingState(LoadingState.ERROR);
    }
  });

  return (
    <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<ErrorWrapper />} state={loadingState}>
      {children}
    </LoadableView>
  );
};
