import { Box, styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

export const NoResultsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${ehiTheme.spacing(4, 0)};
  &.isBackgroundColor {
    background-color: ${ehiTheme.palette.background.default};
  }
`;

export const StyledLink = styled('span')`
  color: ${ehiTheme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`;
