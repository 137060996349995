import { FC } from 'react';
import {
  PeoAmountReadOnly,
  PeoCheckboxContainerReadOnly,
  PeoCodeReadOnly,
  PeoListItemContainerReadOnly,
  PeoQuantityAndRateReadOnly,
  PeoSelectabilityReadOnly,
  PeoTitleReadOnly,
} from './Peo.styles';
import { useTranslations } from 'components/shared/i18n';
import { Rate } from 'services/booking/bookingTypes';
import { getItemRates, getSelectabilityDescription } from 'utils/peoUtils';

export type PeoListItemReadOnlyProps = {
  title: string;
  code: string;
  rate: Rate | undefined;
  selectability: string;
  index: number;
};

export const PeoListItemReadOnly: FC<PeoListItemReadOnlyProps> = ({
  index,
  title,
  code,
  rate,
  selectability,
}: PeoListItemReadOnlyProps) => {
  const { t } = useTranslations();

  return (
    <PeoListItemContainerReadOnly key={`peo-item-readonly-${index}`} data-testid={`peo-item-readonly-${index}`}>
      <PeoCheckboxContainerReadOnly>
        <div>
          <PeoTitleReadOnly data-testid='peoTitle'>{title}</PeoTitleReadOnly>
          <PeoCodeReadOnly data-testid='peoCode'>{code}</PeoCodeReadOnly>
        </div>
      </PeoCheckboxContainerReadOnly>
      <PeoQuantityAndRateReadOnly>
        <div>
          <PeoAmountReadOnly data-testid='peoAmount'>{rate && getItemRates(rate, t, 'en')}</PeoAmountReadOnly>
          <PeoSelectabilityReadOnly data-testid='peoSelectability'>
            {getSelectabilityDescription(selectability, t)}
          </PeoSelectabilityReadOnly>
        </div>
      </PeoQuantityAndRateReadOnly>
    </PeoListItemContainerReadOnly>
  );
};
