import { FC, useMemo } from 'react';
import { Card } from 'components/shared/ui/card/Card';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { StyledBox } from 'components/flexFlow/review/Review.styles';
import { Body1, Body2 } from '@ehi/ui';
import { getDaysDiff, toDateTimeString } from 'utils/dateUtils';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { RentalLocationAddress } from 'components/flexFlow/whenAndWhere/RentalLocationAddress';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';

export const WhenAndWhereSection: FC = (): JSX.Element => {
  const { t } = useTranslations();

  const pickupInfo = useAppSelector(selectPickup);
  const returnInfo = useAppSelector(selectReturn);

  const { data: pickupBranch, isFetching: isFetchingPickupBranch } = useBranchInfoByUrnQuery(pickupInfo?.branch ?? '');

  const { data: returnBranch, isFetching: isFetchingReturnBranch } = useBranchInfoByUrnQuery(returnInfo?.branch ?? '');

  const formattedPickupWeekdayDate = useMemo(() => {
    return toDateTimeString(pickupInfo?.dateTime, t('format.shortWeekdayDate'));
  }, [pickupInfo, t]);

  const formattedPickupTimeTimezone = useMemo(() => {
    return toDateTimeString(pickupInfo?.dateTime, t('format.timeWithZone'), pickupBranch?.timezone);
  }, [pickupInfo, pickupBranch, t]);

  const formattedReturnWeekdayDate = useMemo(() => {
    return toDateTimeString(returnInfo?.dateTime, t('format.shortWeekdayDate'));
  }, [returnInfo, t]);

  const formattedReturnTimeTimezone = useMemo(() => {
    return toDateTimeString(returnInfo?.dateTime, t('format.timeWithZone'), returnBranch?.timezone);
  }, [returnInfo, returnBranch, t]);

  const lengthOfRental = useMemo(() => {
    return getDaysDiff(pickupInfo?.dateTime, returnInfo?.dateTime);
  }, [pickupInfo, returnInfo]);

  return (
    <Card data-testid='whenAndWhereSection' style={{ margin: 0 }}>
      {pickupInfo && returnInfo ? (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} data-testid='pickupContent'>
            <StyledBox>
              <Caption2>{t('whenWhere.rentalStart')}</Caption2>
              <Body2 data-testid='date' display='inline' noWrap={true}>
                {formattedPickupWeekdayDate ? (
                  <b>{`${formattedPickupWeekdayDate} `}</b>
                ) : (
                  <b>{formattedPickupWeekdayDate}</b>
                )}
                {formattedPickupTimeTimezone}
              </Body2>
              <div data-testid='locationInfo'>{pickupBranch && <RentalLocationAddress branch={pickupBranch} />}</div>
            </StyledBox>
          </Grid>
          <Grid item xs={6} sm={6} data-testid='returnContent'>
            <StyledBox>
              <Caption2>{t('whenWhere.rentalReturn')}</Caption2>
              <Body2 data-testid='date' display='inline' noWrap={true}>
                {formattedReturnWeekdayDate ? (
                  <b>{`${formattedReturnWeekdayDate} `}</b>
                ) : (
                  <b>{formattedReturnWeekdayDate}</b>
                )}
                {formattedReturnTimeTimezone}
                {formattedReturnWeekdayDate ? (
                  <b> {`(${lengthOfRental}${lengthOfRental === 1 ? t('whenWhere.day') : t('whenWhere.days')})`}</b>
                ) : (
                  <b></b>
                )}
              </Body2>
              <div data-testid='locationInfo'>{returnBranch && <RentalLocationAddress branch={returnBranch} />}</div>
            </StyledBox>
          </Grid>
        </Grid>
      ) : (
        <Body1 data-testid='noDate' color={'#0000008A'}>
          {t('whenWhere.noDatesSelected')}
        </Body1>
      )}
      <ProgressOverlay inProgress={isFetchingPickupBranch || isFetchingReturnBranch} />
    </Card>
  );
};
