import { RateSourceInfo } from 'components/shared/uiModels/rateSource/rateSourceTypes';
import { parseUrn } from 'utils/urnUtils';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';

export const transformRateSourceInfoFromApi = (accountDetails: ReservationAccount): RateSourceInfo => {
  return {
    urn: accountDetails.urn,
    number: parseUrn(accountDetails.urn),
    name: accountDetails.name,
    type: parseUrn(accountDetails.type),
  };
};
