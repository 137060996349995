import { FC, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useFormContext } from 'react-hook-form';
import { associateRenterToReservationEditor } from 'services/booking/bookingService';
import { Renter } from 'services/booking/bookingTypes';
import { SelectField } from 'components/shared/forms/SelectField';
import { RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { AGE_OPTIONS } from 'components/quickRes/quickResUtils';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { QuickResHeader } from 'components/quickRes/QuickRes.styles';

const QuickResDriver: FC = () => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const [isUpdatingDriver, setIsUpdatingDriver] = useState<boolean>(false);

  const {
    trigger,
    getFieldState,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();

  const handleOnBlur = async (fieldName: string, value?: string) => {
    const formValues = getValues();
    await trigger(fieldName);
    const lastName: string = fieldName === QuickResFields.LastName ? value : formValues[QuickResFields.LastName];
    const firstName: string = fieldName === QuickResFields.FirstName ? value : formValues[QuickResFields.FirstName];
    const age = fieldName === QuickResFields.Age ? value : formValues[QuickResFields.Age];

    if (
      (getFieldState(QuickResFields.LastName).isDirty || getFieldState(QuickResFields.FirstName).isDirty) &&
      !errors[QuickResFields.LastName] &&
      !errors[QuickResFields.FirstName] &&
      !errors[QuickResFields.Age] &&
      lastName?.length > 1 &&
      firstName?.length > 1
    ) {
      setIsUpdatingDriver(true);
      try {
        const data = {
          type: RenterTypes.NoProfile,
          name: {
            given: firstName,
            surname: lastName,
          },
          age: age,
        } as Renter;

        const { errors } = await updateAndRefresh(() => associateRenterToReservationEditor(bookingEditorId, data));
        if (errors) {
          setError(fieldName, { message: errors?.[0]?.localizedMessage });
        }
      } catch (error) {
        setError(fieldName, { message: (error as EhiErrors).errors?.[0]?.localizedMessage });
      } finally {
        setIsUpdatingDriver(false);
      }
    }
  };

  return (
    <GridContainer>
      <GridItem sm={12}>
        <QuickResHeader data-testid={'res-driver'}>{t('driver.driver')}</QuickResHeader>
      </GridItem>
      <GridItem sm={4}>
        <FormTextField
          data-testid={QuickResFields.LastName}
          name={QuickResFields.LastName}
          required={true}
          label={t('driver.lastName')}
          onBlur={(event) => {
            handleOnBlur(QuickResFields.LastName, event.target.value);
          }}></FormTextField>
      </GridItem>
      <GridItem sm={4}>
        <FormTextField
          name={QuickResFields.FirstName}
          required={true}
          label={t('driver.firstName')}
          onBlur={(event: any) => {
            handleOnBlur(QuickResFields.FirstName, event.target.value);
          }}></FormTextField>
      </GridItem>
      <GridItem sm={4}>
        <SelectField
          name={QuickResFields.Age}
          label={t('driver.age')}
          data-testid={QuickResFields.Age}
          options={AGE_OPTIONS}
          hasNoneOption={false}
          onChange={(event: any) => {
            setValue(QuickResFields.Age, event.target.value);
            handleOnBlur(QuickResFields.Age, event.target.value);
          }}
        />
      </GridItem>
      <ProgressOverlay inProgress={isUpdatingDriver} />
    </GridContainer>
  );
};
export default QuickResDriver;
