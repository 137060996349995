import { DriverData, RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { useAppSelector } from 'redux/hooks';
import {
  selectContact,
  selectDriverProfileRenter,
  selectRenter,
  selectTransactionalProfileRenter,
} from 'redux/selectors/bookingEditor';
import { parseUrn } from 'utils/urnUtils';
import {
  transformToDriverFromDriverProfile,
  transformToDriverFromNoProfile,
  transformToDriverFromTransactionalProfile,
} from 'components/shared/uiModels/driver/driverTransformer';
import {
  useGetDriverProfileLoyaltyMembership,
  useGetDriverProfileQuery,
  useGetLoyaltyProgram,
} from 'services/renter/driverProfile/driverProfileQueries';
import { NoProfileRenter } from 'services/booking/bookingTypes';
import { logError } from 'components/shared/logger/splunkLogger';
import { useGetTransactionalProfileQuery } from 'services/renter/transactionalProfile/transactionalProfileQueries';

export type GetRenterProfileType = {
  driverData: DriverData | undefined;
  isRenterProfileLoading: boolean;
  isRenterProfileError: boolean;
  refetchDriverProfile: () => void;
};

export const useGetRenterProfile = (): GetRenterProfileType => {
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const transactionalProfileRenter = useAppSelector(selectTransactionalProfileRenter);
  const renter = useAppSelector(selectRenter);
  const contact = useAppSelector(selectContact);

  const {
    data: driverProfileData,
    isFetching: isGetDriverProfileLoading,
    isError: isGetDriverProfileError,
    refetch,
  } = useGetDriverProfileQuery(parseUrn(driverProfileRenter?.profile), {
    enabled: !!driverProfileRenter?.profile && renter?.type === RenterTypes.DriverProfile,
  });
  const {
    data: loyaltyMembership,
    isFetching: isLoyaltyMembershipLoading,
    isError: isLoyaltyMembershipError,
  } = useGetDriverProfileLoyaltyMembership(
    parseUrn(driverProfileRenter?.profile),
    parseUrn(driverProfileRenter?.membership?.loyaltyProgram),
    {
      enabled:
        !!driverProfileRenter?.profile && !!driverProfileRenter?.membership?.loyaltyProgram && !!driverProfileData,
    }
  );

  const isLoyaltyProgramEnabled =
    !!driverProfileRenter?.profile &&
    !!driverProfileRenter?.membership?.loyaltyProgram &&
    !!driverProfileData &&
    !!loyaltyMembership;
  const { data: loyaltyPrograms, isLoading: isLoadingLoyalty } = useGetLoyaltyProgram(isLoyaltyProgramEnabled);
  const isLoyaltyProgramsLoading = isLoyaltyProgramEnabled && isLoadingLoyalty;
  const {
    data: transactionalProfileData,
    isLoading: isGetTransactionalProfileLoading,
    isError: isGetTransactionalProfileError,
  } = useGetTransactionalProfileQuery(parseUrn(transactionalProfileRenter?.profile), {
    enabled: !!transactionalProfileRenter?.profile && renter?.type === RenterTypes.TransactionalProfile,
  });

  if (RenterTypes.NoProfile === renter?.type) {
    return {
      driverData: transformToDriverFromNoProfile(renter as NoProfileRenter, contact),
      isRenterProfileLoading: false,
      isRenterProfileError: false,
      refetchDriverProfile: refetch,
    };
  } else if (RenterTypes.DriverProfile === renter?.type) {
    (isGetDriverProfileError || isLoyaltyMembershipError) &&
      logError({ message: 'No Renter Information From Booking' });
    return {
      driverData:
        driverProfileData &&
        transformToDriverFromDriverProfile(driverProfileData, loyaltyPrograms ?? [], loyaltyMembership),
      isRenterProfileLoading: isGetDriverProfileLoading || isLoyaltyMembershipLoading || isLoyaltyProgramsLoading,
      isRenterProfileError: isGetDriverProfileError || isLoyaltyMembershipError,
      refetchDriverProfile: refetch,
    };
  } else if (RenterTypes.TransactionalProfile == renter?.type) {
    isGetTransactionalProfileError && logError({ message: 'No Renter Information From Booking' });
    return {
      driverData: transactionalProfileData && transformToDriverFromTransactionalProfile(transactionalProfileData),
      isRenterProfileLoading: isGetTransactionalProfileLoading,
      isRenterProfileError: isGetTransactionalProfileError,
      refetchDriverProfile: refetch,
    };
  }

  return {
    driverData: undefined,
    isRenterProfileLoading: false,
    isRenterProfileError: false,
    refetchDriverProfile: refetch,
  };
};
