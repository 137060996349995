import { CardContent, Grid } from '@mui/material';
import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { Body2, ehiTheme } from '@ehi/ui';
import { RouterPaths } from 'app/router/RouterPaths';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { FC } from 'react';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';

export const RateSourceAndBillToSummary: FC = (): JSX.Element => {
  const { t } = useTranslations();
  const { rateSourceInfo, isRateSourceInfoLoading, rateSourceErrors } = useAccountDetails();

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.rateSource')}
      loading={mapLoadingState(isRateSourceInfoLoading, !!rateSourceErrors)}
      path={RouterPaths.RateAndBill}
      errors={[...(rateSourceErrors?.errors || [])]}
      data-testid='rateSourceSummaryCard'>
      <CardContent data-testid='rateSourceSummaryContent'>
        <Grid container direction={'column'}>
          <Grid item xs data-testid='rateSourceDetails'>
            <Caption2 paddingRight={ehiTheme.spacing(1)}>{t('rateAndBilling.rateSource')}</Caption2>
            <Caption2 bold={!!rateSourceInfo?.name} data-testid='rateSourceAccountName'>
              {rateSourceInfo?.name ?? t('rateAndBilling.retail')}
            </Caption2>
            {rateSourceInfo?.type && <Body2 data-testid='rateSourceAccountType'>{rateSourceInfo.type}</Body2>}
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
