import {
  Address,
  BusinessAccountSearchResult,
  ContactInformation,
  ContactPoint,
  Phone,
  ReservationAccount,
} from 'services/businessAccount/businessAccountTypes';
import { AccountDetailsType } from 'components/flexFlow/rateAndBilling/editDialogs/accountSearchUtils';
import { parseUrn } from 'utils/urnUtils';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';

const getAddressLines = (address: Address | undefined) => {
  const addressLines = [];
  address?.line1 && addressLines.push(address.line1);
  address?.line2 && addressLines.push(address.line2);
  address?.line3 && addressLines.push(address.line3);
  address?.line4 && addressLines.push(address.line4);
  return addressLines.join(' ');
};

const getCityStateZip = (address: Address | undefined) => {
  const cityStateZip = [];
  const cityString = address?.city && address.countrySubdivision ? `${address.city}, ` : address?.city;
  address?.city && cityStateZip.push(cityString);
  address?.countrySubdivision && cityStateZip.push(parseUrn(address.countrySubdivision));
  address?.postalCode && cityStateZip.push(address.postalCode);
  address?.country && cityStateZip.push(parseUrn(address.country));
  return cityStateZip.join(' ');
};

const getPhoneNumber = (contact?: ContactInformation) => {
  const phoneInfo = contact?.contactPoints?.find(
    (contact: ContactPoint) => contact.type === 'PHONE' && contact.priority === 1
  ) as Phone | undefined;
  return formatPhoneNumber(phoneInfo?.phoneNumber);
};

export const transformAccountDetailsFromRetrieve = (
  account: ReservationAccount | undefined,
  contact: ContactInformation | undefined
): AccountDetailsType => {
  return {
    name: account?.name ?? '',
    accountNumber: parseUrn(account?.urn),
    accountNumberUrn: account?.urn ?? '',
    accountType: parseUrn(account?.type),
    accountAddress1: getAddressLines(contact?.address),
    accountAddress2: getCityStateZip(contact?.address),
    phoneNumber: getPhoneNumber(contact),
  };
};

export const transformAccountDetailsFromSearch = (account: BusinessAccountSearchResult): AccountDetailsType => {
  return {
    name: account?.name ?? '',
    accountNumber: parseUrn(account.urn),
    accountNumberUrn: account.urn ?? '',
    accountType: parseUrn(account.type),
    accountAddress1: getAddressLines(account.mainAddress),
    accountAddress2: getCityStateZip(account.mainAddress),
    phoneNumber: formatPhoneNumber((account as any).mainPhone?.phoneNumber),
  };
};
