import { Paper, styled, TableCell } from '@mui/material';
import { Body1, ehiTheme } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';

export const TableContainer = styled(Paper)`
  box-shadow: none;
`;

export const RowItemLabel = styled(Caption2)`
  color: #0000008a;
`;

export const RowItem = styled(TableCell)`
  border-bottom: 1px solid #0000003b;
  padding: ${ehiTheme.spacing(1.25, 2)};
`;

export const RowItemText = styled(Body1)`
  color: #0000008a;
`;
