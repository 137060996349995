import { FC, useMemo } from 'react';
import { Body1, ehiTheme } from '@ehi/ui';
import { Box, Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Person as PassengerIcon, WorkOutline as LuggageIcon } from '@mui/icons-material';
import { VehicleSpecGridItem, VehicleSpecText } from 'components/flexFlow/review/Review.styles';
import AutoTransmissionDarkIcon from 'images/autoTransmissionDarkIcon.svg';
import { useTranslations } from 'components/shared/i18n';
import { getAutoTransmissionDescription, getCarClassAndDescription } from 'utils/vehicleUtils';
import { useAppSelector } from 'redux/hooks';
import { parseUrn } from 'utils/urnUtils';
import { selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { Vehicle, Vehicles } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import { VehicleFeatures } from 'components/flexFlow/vehicle/VehicleFeatures';

export type VehicleInfoModalContentType = {
  vehicles: Vehicles;
};
export const VehicleInfoModalContent: FC<VehicleInfoModalContentType> = ({ vehicles }: VehicleInfoModalContentType) => {
  const { t } = useTranslations();
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const vehicleClass = getCarClassAndDescription(parseUrn(vehicleClassSelection?.preferred), vehicles);

  const selectedVehicle = useMemo(() => {
    return vehicles.find((vehicle: Vehicle) => vehicle.vehicleClass === parseUrn(vehicleClassSelection?.reserved));
  }, [vehicleClassSelection?.reserved, vehicles]);

  return (
    <Grid container direction={'column'} marginTop={ehiTheme.spacing(-3)} data-testid={'vehicleInformationModal'}>
      <Grid item>
        <Caption2>{t('vehicle.vehicleClass')}</Caption2>
        <Body1 bold>{vehicleClass}</Body1>
      </Grid>
      <Grid item paddingTop={ehiTheme.spacing(1)}>
        <Grid container>
          <Grid item xs={6}>
            <Caption2>{t('vehicle.vehicleDescription')}</Caption2>
            <Body1 bold>{selectedVehicle?.similarModels + ' ' + t('vehicle.similarModels')}</Body1>
          </Grid>
          <Grid item xs={6}>
            <Caption2>{t('vehicle.vehicleSpecs')}</Caption2>
            <Grid data-testid='vehicleSpecs' container spacing={2}>
              <VehicleSpecGridItem data-testid='transmissionType'>
                <img src={AutoTransmissionDarkIcon} alt={selectedVehicle?.transmissionType} />
                <VehicleSpecText bold color={'#000000DE'}>
                  {getAutoTransmissionDescription(t, selectedVehicle?.transmissionType)}
                </VehicleSpecText>
              </VehicleSpecGridItem>
              <VehicleSpecGridItem data-testid='passengers'>
                <PassengerIcon fontSize={'small'} style={{ color: '#000000DE' }} />
                <VehicleSpecText bold color={'#000000DE'}>
                  {selectedVehicle?.passengers}
                </VehicleSpecText>
              </VehicleSpecGridItem>
              <VehicleSpecGridItem data-testid='luggageSpace'>
                <LuggageIcon fontSize={'small'} style={{ color: '#000000DE' }} />
                <VehicleSpecText bold color={'#000000DE'}>
                  {selectedVehicle?.luggageSpace}
                </VehicleSpecText>
              </VehicleSpecGridItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item marginTop={ehiTheme.spacing(-1)}>
        <Box data-testid='featuresSection' bgcolor={'#F5F5F5'} padding={ehiTheme.spacing(1, 2)}>
          <Grid container>
            <Grid item xs={3}>
              <img
                data-testid='vehicleImage'
                src={selectedVehicle?.imageKey}
                alt={'vehicleImage'}
                height={'100%'}
                width={'100%'}
                style={{ maxHeight: '140px', maxWidth: '192px' }}
              />
            </Grid>
            <Grid item xs={9}>
              <VehicleFeatures vehicle={selectedVehicle} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
