import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';

export type BranchSearchResult = {
  branches: BranchLookupCard[];
  searchInputText: string;
};

export enum SearchByType {
  Keyword = 'keyword',
  PostalCode = 'postalCode',
}

export type locationOptionType = { name: string; code: string };

export const HOME_CITY_URN = 'urn:com.ehi:xqa:reference:location:operationalLocationType:HOME_CITY';
export const HOME_CITY = 'HOME_CITY';
export const HOME_CITY_DESCRIPTION = 'Located in a home city';

export interface BranchLookupValues {
  [BranchLookupFields.SearchBy]: string;
  [BranchLookupFields.Country]: string;
  [BranchLookupFields.Groups]: string[];
  [BranchLookupFields.Brands]: string[];
  [BranchLookupFields.LocationTypes]: locationOptionType[] | [];
  [BranchLookupFields.Distance]: string;
  [BranchLookupFields.IsListButtonSelected]: boolean;
  [BranchLookupFields.IsMapButtonSelected]: boolean;
  [BranchLookupFields.SearchInputValue]: string;
  [BranchLookupFields.SelectedBranch]: BranchLookupCard | undefined;
}

export enum BranchLookupFields {
  SearchBy = 'searchBy',
  Country = 'countrySelect',
  Groups = 'groupsFilter',
  Brands = 'brandFilter',
  LocationTypes = 'locationTypesFilter',
  Distance = 'distanceFilter',
  HideDistance = 'hideDistance',
  IsListButtonSelected = 'isListSelected',
  IsMapButtonSelected = 'isMapSelected',
  SearchInputValue = 'searchInputValue',
  SelectedBranch = 'selectedBranch',
}

export interface RentalBrand {
  urn: string;
  code: string;
  locale: string;
  name: string;
  legacyCode: string;
}
