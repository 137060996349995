import { logDebug } from 'utils/logUtils';

const URN_PREFIX = 'urn';
const COM_EHI_NAMESPACE = 'com.ehi';

const verifyUrnString = (urnString: string): string[] => {
  const parts = urnString.split(':');
  if (parts) {
    if (parts[0] !== URN_PREFIX) {
      logDebug(`URN string did not start with 'urn': ${urnString}`);
    } else if (parts[1] !== COM_EHI_NAMESPACE) {
      logDebug(`namespace was not 'com.ehi': ${urnString}`);
    }
  }
  return parts;
};

/**
 * Verifies the urn, then returns the last element in the urn
 * If the urn is invalid, returns nothing
 * @param urn the urn to parse
 */
export const parseUrn = (urn: string | undefined): string => {
  if (urn === undefined || urn === null) return '';
  const parts = verifyUrnString(urn);
  if (parts && parts.length > 1) {
    return parts[parts.length - 1];
  }
  return parts[0];
};

export const parseUrnItem = (urn: string, item: string): string => {
  let returnValue = '';
  if (urn !== undefined && urn !== '' && urn.indexOf(':') !== -1) {
    const valueArray = verifyUrnString(urn);
    let found = false;
    for (const element of valueArray) {
      if (found) {
        returnValue = element;
        break;
      }
      if (item === element) {
        found = true;
      }
    }
  }
  return returnValue;
};

export const EHI_DOMAINS = {
  reference: { name: 'reference' },
  rental: {
    name: 'rental',
    booking: 'booking',
    reservation: 'reservation',
    person: 'person',
    paymentMethod: 'paymentMethod',
  },
  rentalRate: {
    name: 'rental.rate',
    brand: 'brand',
    rateProduct: 'rateProduct',
  },
  vehicle: {
    name: 'vehicle',
    vehicleClass: 'vehicleClass',
  },
  renter: {
    name: 'renter',
    legalIdentification: 'legalIdentificationType',
    phoneType: 'phoneType',
  },
  location: {
    name: 'location',
    country: 'country',
    countrySubdivision: 'countrySubdivision',
    branch: 'branch',
    businessIndicator: 'businessIndicator',
  },
  businessAccount: {
    name: 'businessAccount',
    account: 'account',
    billingAccount: 'billingAccount',
    accountType: 'accountType',
  },
};

export const generateUrn = (domain: string, resource: string, id: string, environment: string): string => {
  return `${URN_PREFIX}:${COM_EHI_NAMESPACE}:${environment}:${domain}:${resource}:${id}`;
};

export const generateReferenceUrn = (domain: string, resource: string, id: string, environment: string): string => {
  return generateUrn(`${EHI_DOMAINS.reference.name}:${domain}`, resource, id, environment);
};

export const generateUrnForReservation = (resNumber: string, environment: string): string => {
  return generateUrn(
    `${EHI_DOMAINS.rental.name}.${EHI_DOMAINS.rental.booking}`,
    EHI_DOMAINS.rental.reservation,
    resNumber,
    environment
  );
};

export const generateUrnForBillingAccount = (
  accountNumber: string,
  billingNumber: string,
  environment: string
): string => {
  return generateUrn(
    EHI_DOMAINS.businessAccount.name,
    `${EHI_DOMAINS.businessAccount.account}:${accountNumber.toUpperCase()}:${
      EHI_DOMAINS.businessAccount.billingAccount
    }`,
    billingNumber.toUpperCase(),
    environment
  );
};
