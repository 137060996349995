import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import { AutoCompleteField, AutoCompleteOption } from 'components/shared/forms/AutoCompleteField';
import { useCountriesQuery } from 'services/location/locationQueries';
import { generateAllCountryMenuItemsWithCorporate } from 'utils/formUtils';
import { QuickResFields } from './QuickResTypes';

interface QuickResContactCountryCodeProps {
  name: string;
}

export const QuickResContactCountryCode: React.FC<QuickResContactCountryCodeProps> = ({ name }) => {
  const { t } = useTranslations();
  const { setValue, control } = useFormContext();

  const selectedCountry = useWatch({
    control,
    name,
  });

  const { data } = useCountriesQuery();

  const countryOptions = useMemo(() => {
    if (!data?.length) return [];

    const countryData = generateAllCountryMenuItemsWithCorporate(data);

    return countryData.map((country) => ({
      key: country.value,
      title: country.label,
    }));
  }, [data]);

  const handleCountryChange = (newValue: AutoCompleteOption) => {
    setValue(name, newValue);
  };

  return (
    <AutoCompleteField
      name={name}
      label={t('location.countryCode')}
      data-testid={QuickResFields.CountryCode}
      options={countryOptions}
      handleOnChange={handleCountryChange}
      value={selectedCountry || { key: '', title: '' }}
      loading={false}
      placeholder={t('location.countryCode')}
    />
  );
};
