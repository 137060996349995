import { createRef, FC, RefObject, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  BranchLookupFields,
  BranchLookupValues,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { BranchLookupSearch } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupSearch';
import { BranchLookupFilters } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupFilters';
import BranchCardList from 'components/flexFlow/whenAndWhere/branchLookup/BranchCardList';
import { StyledDialog } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { useTranslations } from 'components/shared/i18n';
import { useFetchBranches } from 'components/flexFlow/whenAndWhere/branchLookup/useFetchBranches';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import {
  useBranchInfoByUrnQuery,
  useLocationGroups,
  useLocationQuery,
  useRentalBrandsQuery,
} from 'services/location/locationQueries';
import { loadEhiLocationCookie } from '@ehi/location';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import { DateTime } from 'luxon';
import { useDateTimeFormater } from 'utils/routing/useDatetimeFormater';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logError } from 'components/shared/logger/splunkLogger';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { Box } from '@mui/material';
import { VirtuosoHandle } from 'react-virtuoso';
import { DAILY_RENTAL, getBranchLookupInitialValues, getBrandOptions, getGroupOptions } from 'utils/branchLookupUtils';
import { useMemoizedOptions, useMemoizedString } from 'hooks/useMemoizedOptions';
import { getDefaultCountry } from 'utils/locationUtils';

type BranchLookupModalProps = {
  open: boolean;
  pickupOrDropOffLocation: string;
  handleCancel: () => void;
  title: string;
  handleApply: (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string
  ) => void;
};

export const BranchLookupModal: FC<BranchLookupModalProps> = ({
  open,
  pickupOrDropOffLocation,
  handleCancel,
  title,
  handleApply,
}: BranchLookupModalProps) => {
  const { t } = useTranslations();
  const cookie = loadEhiLocationCookie();
  const { showAlert } = useAlert();
  const { getLocalizedDateTime } = useDateTimeFormater();
  const { string: defaultCountry, isLoading: isLocationQueryLoading } = useMemoizedString(
    useLocationQuery(cookie?.peoplesoftId ?? ''),
    getDefaultCountry
  );
  const { options: groupOptions, isLoading: isLocationGroupsLoading } = useMemoizedOptions(
    useLocationGroups(defaultCountry, DAILY_RENTAL),
    getGroupOptions
  );
  const { options: brandOptions, isLoading: isRentalBrandsLoading } = useMemoizedOptions(
    useRentalBrandsQuery(),
    getBrandOptions
  );

  const isInitialValuesLoading = isLocationQueryLoading || isLocationGroupsLoading || isRentalBrandsLoading;

  const initialValues = useMemo(() => {
    return getBranchLookupInitialValues(defaultCountry, groupOptions, brandOptions, t);
  }, [defaultCountry, groupOptions, brandOptions, t]);

  const formMethods = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    formMethods.reset(initialValues);
  }, [formMethods, initialValues]);

  const [branchResults, setBranchResults] = useState<
    { branches: BranchLookupCard[]; searchInputText: string } | undefined
  >();
  const branchListRef: RefObject<VirtuosoHandle> | undefined = createRef();
  const [isMapView, setMapView] = useState<boolean>(false);
  const selectedBranch = formMethods.watch(BranchLookupFields.SelectedBranch);
  const { executeSearch, isLoading } = useFetchBranches(formMethods.getValues());
  const { isFetching: isFetchingBranchInfo, refetch: refetchBranchInfo } = useBranchInfoByUrnQuery(
    (selectedBranch as BranchLookupCard | undefined)?.branchUrn,
    false
  );
  const toggleMapView = (isMapView: boolean) => {
    setMapView(isMapView);
    branchListRef.current && branchListRef.current.scrollToIndex(0);
  };

  const handleSearch = async () => {
    branchListRef.current && branchListRef.current.scrollToIndex(0);
    const branches = await executeSearch();
    setBranchResults({ branches, searchInputText: formMethods.getValues(BranchLookupFields.SearchInputValue) });
  };

  const onSubmit = async (values: BranchLookupValues) => {
    const selectedBranch = values[BranchLookupFields.SelectedBranch];
    if (selectedBranch?.branchUrn && selectedBranch.stationId) {
      try {
        const { data: branchInfo, error } = await refetchBranchInfo();
        if (error) {
          await showAlert({ responseMessages: error?.errors });
        } else {
          handleApply(
            selectedBranch?.branchUrn,
            selectedBranch?.stationId,
            getLocalizedDateTime(branchInfo?.timezone ?? '', DateTime.now()),
            branchInfo?.timezone ?? undefined
          );
        }
      } catch (error) {
        logError({ message: `No branch info details for ${selectedBranch?.branchUrn}` });
        handleApply(
          selectedBranch?.branchUrn,
          selectedBranch?.stationId,
          getLocalizedDateTime('', DateTime.now()),
          undefined
        );
      }
    } else {
      await showAlert({
        title: t('error.error'),
        description: t('whenWhere.branchError'),
      });
    }
  };

  const handleReset = () => {
    formMethods.reset();
    setBranchResults(undefined);
    setMapView(false);
  };

  const hasNoResults = branchResults?.branches?.length === 0;

  return (
    <FormProvider {...formMethods}>
      <StyledDialog
        PaperProps={{
          sx: {
            height: '94%',
            width: '96%',
          },
        }}
        data-testid={`branch-lookup-modal-${pickupOrDropOffLocation}`}
        id={'branch-lookup-dialog'}
        contentPadding={0}
        open={open}
        title={title}
        a11yKey='content'
        fullScreen
        showDividers={true}
        actions={{
          secondaryAction: {
            label: t('common.cancel'),
            onClick: handleCancel,
          },
          primaryAction: {
            label: t('common.apply'),
            onClick: formMethods.handleSubmit(onSubmit),
            overrideButtonProps: { disabled: !selectedBranch },
          },
        }}>
        <BranchLookupSearch
          onSearch={handleSearch}
          handleReset={handleReset}
          defaultCountry={initialValues.countrySelect}
        />
        <EhiDivider />
        <BranchLookupFilters
          onMapViewToggle={toggleMapView}
          hasResults={!!branchResults}
          brandOptions={initialValues.brandFilter}
          locationTypeOptions={initialValues.locationTypesFilter}
        />
        <EhiDivider />
        {hasNoResults ? (
          <Box paddingTop={12}>
            <NoResultsView
              noResultsTitle='whenWhere.noResultsTitle'
              noResultsDescription='whenWhere.noResultsDescription'
              isBackgroundColor={false}
              pageTitle={true}
            />
          </Box>
        ) : (
          <BranchCardList
            branches={branchResults?.branches ?? []}
            searchInputText={branchResults?.searchInputText ?? ''}
            branchListRef={branchListRef}
            isMapView={isMapView}
          />
        )}
        <ProgressOverlay inProgress={isLoading || isFetchingBranchInfo || isInitialValuesLoading} />
      </StyledDialog>
    </FormProvider>
  );
};
