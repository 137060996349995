import { TFunction } from 'i18next';

export enum paymentTypes {
  Business_Account = 'BUSINESS_ACCOUNT',
  Credit_Card = 'CREDIT_CARD',
  Debit_Card = 'DEBIT_CARD',
  Other = 'UNKNOWN',
}

export type paymentOptionType = {
  key: string;
  title: string;
};

export const getPaymentTypeOptions = (t: TFunction<'translation'>): paymentOptionType[] => {
  return [
    { key: paymentTypes.Business_Account, title: t('payment.billTo') },
    { key: paymentTypes.Credit_Card, title: t('payment.creditCard') },
    { key: paymentTypes.Debit_Card, title: t('payment.debitCard') },
    { key: paymentTypes.Other, title: t('payment.other') },
  ];
};
