import { useTranslation } from 'react-i18next';
import {
  convertStringToIso2Locales,
  enUS,
  frCA,
  frUS,
  Iso2Locales,
  loadLocaleCookie,
  localeMap,
  saveLocale,
} from '@ehi/location';
import { isValidLocale } from 'components/shared/i18n/locales';
import { useCallback, useMemo } from 'react';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';

const logFailedLanguageChange = (info: any) =>
  logError({
    error: { message: 'Error setting language', supportInformation: info },
    severity: ErrorSeverity.Warning,
  });

export const useLocale = () => {
  const { i18n } = useTranslation();
  /**
   * Should only be used within useLocale hook
   */
  const setNewLocale = useCallback(
    async (newLocale: Iso2Locales | undefined): Promise<void> => {
      if (!newLocale || !isValidLocale(newLocale)) {
        logFailedLanguageChange(`new locale is ${newLocale}`);
      } else {
        try {
          await i18n.changeLanguage(newLocale);
          saveLocale(newLocale);
        } catch (er) {
          logFailedLanguageChange({ exception: er, locale: newLocale });
        }
      }
    },
    [i18n]
  );
  const locale = useMemo(() => (isValidLocale(i18n.language) ? i18n.language : Iso2Locales.enUS), [i18n.language]);

  const initLocaleForLocation = async ({
    branchIso2Language,
    branchIso2Country,
  }: {
    branchIso2Language: string;
    branchIso2Country: string;
  }) => {
    const preferredLocale = loadLocaleCookie();
    const currentLocaleItem = preferredLocale && localeMap.get(preferredLocale);

    // only update language for location if one was not set yet
    if (!currentLocaleItem) {
      const locale = convertStringToIso2Locales(`${branchIso2Language}-${branchIso2Country}`);
      await setNewLocale(locale);
    } else {
      const locale = convertStringToIso2Locales(`${currentLocaleItem.iso2Language}-${branchIso2Country}`);
      await setNewLocale(locale);
    }
  };

  const setUserPreferredLanguage = useCallback(
    async (iso2Language: string, countryIso2: string) => {
      const newLocale = convertStringToIso2Locales(`${iso2Language}-${countryIso2}`);
      await setNewLocale(newLocale);
    },
    [setNewLocale]
  );

  const servicesIso3Locale = useMemo(() => {
    const localeItem = localeMap.get(locale);

    // This is super silly, but patching services to not break current functionality
    // need to make services update if we ever start supporting other languages or countries
    if (frUS === localeItem) {
      return frCA.iso3Locale;
    } else {
      return localeItem?.iso3Locale || enUS.iso3Locale;
    }
  }, [locale]);

  return { locale, initLocaleForLocation, setUserPreferredLanguage, servicesIso3Locale };
};
