import { styled } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SaveIcon from '@mui/icons-material/Save';
import { ehiTheme } from '@ehi/ui';

export const StyledCreateIconButton = styled(PostAddIcon)(() => ({
  paddingRight: ehiTheme.spacing(0.5),
}));

export const StyledModifyIconButton = styled(SaveIcon)(() => ({
  paddingRight: ehiTheme.spacing(1),
  fontSize: 'medium',
}));
