import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { BillToSection } from 'components/flexFlow/rateAndBilling/BillToSection';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { RateSourceSection } from 'components/flexFlow/rateAndBilling/RateSourceSection';

export const RateAndBilling: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  return (
    <FlexFlowCard loadingState={mapLoadingState(false, false)}>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <FlexiFlowCardInnerContainer style={{ padding: 0 }}>
          <RateSourceSection />
          <EhiDivider />
          <BillToSection />
        </FlexiFlowCardInnerContainer>
      </CardContent>
    </FlexFlowCard>
  );
};
