import React, { ReactNode, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslations } from 'components/shared/i18n';
import { useFormContext } from 'react-hook-form';
import { Checkbox, IconButton, ListItemText, ListSubheader, MenuItem, Switch, TextField } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { EMPTY_VALUE } from 'utils/constants';
import { Search } from '@mui/icons-material';
import { parseUrn } from 'utils/urnUtils';
import {
  AllToggleText,
  ButtonContainer,
  EhiButtonReset,
  HeaderContainer,
  HeaderTitle,
  StyledFormTextField,
  SubHeaderContainer,
  SubHeaderTitle,
} from 'components/shared/forms/Forms.styles';
import { useLocationGroups } from 'services/location/locationQueries';
import { useMemoizedOptions } from 'hooks/useMemoizedOptions';
import { DAILY_RENTAL, getGroupOptions } from 'utils/branchLookupUtils';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { GroupSummary } from 'services/location/locationTypes';

export type GroupDropDownProps = {
  groupFormFieldName: string;
  countryFormFieldName: string;
  required?: boolean;
};

export const GroupDropDown: React.FC<GroupDropDownProps> = ({
  groupFormFieldName,
  countryFormFieldName,
  required,
}: GroupDropDownProps) => {
  const [searchTerm, setSearchTerm] = useState<string>(EMPTY_VALUE);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslations();
  const { watch, setValue } = useFormContext();
  const selectedGroups: string[] = watch(groupFormFieldName);
  const selectedCountry: string = watch(countryFormFieldName);

  const updateGroupInfo = useCallback(
    (groups?: GroupSummary[]) => {
      const newGroupOptions = getGroupOptions(groups);
      setValue(
        groupFormFieldName,
        newGroupOptions.map((option) => option.urn)
      );
      return newGroupOptions;
    },
    [groupFormFieldName, setValue]
  );

  const { options: groupOptions, isLoading: isLocationGroupsLoading } = useMemoizedOptions(
    useLocationGroups(selectedCountry, DAILY_RENTAL),
    updateGroupInfo
  );
  const allGroupsSelected = useMemo(() => {
    return groupOptions && groupOptions.length === selectedGroups.length;
  }, [groupOptions, selectedGroups.length]);

  const toggleAllGroups = useCallback(() => {
    allGroupsSelected
      ? setValue(groupFormFieldName, [])
      : setValue(
          groupFormFieldName,
          groupOptions.map((option) => option.urn)
        );
  }, [allGroupsSelected, setValue, groupFormFieldName, groupOptions]);

  const getFilteredOptions = useCallback(() => {
    return groupOptions
      .filter((option) => searchTerm.length === 0 || option.name?.toLowerCase().includes(searchTerm.toLowerCase()))
      .map((option) => {
        return (
          <MenuItem
            key={option.urn}
            value={option.urn}
            data-testid={`group-${parseUrn(option.urn)}`}
            style={{ padding: ehiTheme.spacing(1, 0.5) }}>
            <Checkbox checked={!!(option?.urn && selectedGroups.includes(option?.urn))} />
            <ListItemText primary={option.name} primaryTypographyProps={{ style: { whiteSpace: 'normal' } }} />
          </MenuItem>
        );
      });
  }, [groupOptions, searchTerm, selectedGroups]);

  const handleReset = useCallback(() => {
    setSearchTerm(EMPTY_VALUE);
    setValue(
      groupFormFieldName,
      groupOptions.map((option) => option.urn)
    );
  }, [groupFormFieldName, groupOptions, setValue]);

  const handleOpen = useCallback((event: SyntheticEvent<Element, Event>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setSearchTerm(EMPTY_VALUE);
    setIsOpen(false);
  }, []);

  const renderSelectedGroups = useCallback(() => {
    if (selectedGroups.length === 0 || allGroupsSelected) {
      return t('common.all');
    } else {
      const label = groupOptions.find((option) => option.urn === selectedGroups[0])?.name;
      return selectedGroups.length === 1 ? label : `${label?.substring(0, 15)}..., +${selectedGroups?.length - 1}`;
    }
  }, [selectedGroups, allGroupsSelected, t, groupOptions]);

  const SearchIcon = (): ReactNode => {
    return <Search color={'secondary'} style={{ paddingRight: ehiTheme.spacing(1) }} />;
  };

  return (
    <StyledFormTextField
      name={groupFormFieldName}
      data-testid='groupDropDown'
      label={required ? t('whenWhere.groupNumber*') : t('whenWhere.groupNumber')}
      InputLabelProps={{ shrink: true }}
      select
      sx={{
        '&& .Mui-focused': {
          color: '#000000',
        },
      }}
      SelectProps={{
        open: isOpen,
        multiple: true,
        displayEmpty: true,
        notched: true,
        value: selectedGroups,
        renderValue: renderSelectedGroups,
        MenuProps: {
          variant: 'menu',
          disableAutoFocusItem: true,
          anchorEl: anchorEl,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '&& .Mui-selected': { backgroundColor: '#ffffff' },
            transition: 'none !important',
          },
          slotProps: {
            paper: {
              style: {
                width: anchorEl ? `${anchorEl.clientWidth * 1.25}px` : 'auto',
                maxHeight: window.innerHeight / 1.75,
                ...(window.innerWidth <= 800 && {
                  minWidth: '25%',
                }),
              },
            },
            root: { sx: { '.MuiList-root': { padding: 0 } } },
          },
        },
        onClose: handleClose,
        onOpen: handleOpen,
      }}>
      <ListSubheader>
        <HeaderContainer>
          <HeaderTitle>{t('whenWhere.groupNumber')}</HeaderTitle>
          <IconButton size='small' onClick={handleClose}>
            <CloseIcon color={'secondary'} data-testid='group-close-dropdown' />
          </IconButton>
        </HeaderContainer>
        <HeaderContainer>
          <TextField
            value={searchTerm}
            placeholder={t('whenWhere.searchForGroupNumber')}
            data-testid={'search-group-field'}
            fullWidth
            type={'text'}
            autoFocus
            InputProps={{
              startAdornment: SearchIcon(),
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </HeaderContainer>
        <HeaderContainer>
          <AllToggleText>{t('whenWhere.allGroupNumbers')}</AllToggleText>
          <Switch checked={allGroupsSelected} onChange={toggleAllGroups} data-testid='allGroupsSwitch' edge={'end'} />
        </HeaderContainer>
        <HeaderContainer>
          <SubHeaderTitle>{t('whenWhere.groupNumbers')}</SubHeaderTitle>
        </HeaderContainer>
      </ListSubheader>
      {getFilteredOptions()}
      <SubHeaderContainer style={{ bottom: 0 }}>
        <ButtonContainer>
          <EhiButtonReset
            onClick={handleReset}
            data-testid='branchLookupGroupReset'
            sx={{ margin: `${ehiTheme.spacing(0.5, 0.5, 0, 0)}` }}>
            {t('common.reset')}
          </EhiButtonReset>
        </ButtonContainer>
      </SubHeaderContainer>
      <ProgressOverlay inProgress={isLocationGroupsLoading} />
    </StyledFormTextField>
  );
};
