import { createContext, FC, ReactNode, useCallback, useState } from 'react';
import { VehicleListContextProps } from 'context/vehicleList/VehicleListContextTypes';

export const VehicleListContext = createContext<VehicleListContextProps>({
  displayVehicleList: false,
  toggleVehicleList: () => undefined,
});

export const VehicleListProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [displayVehicleList, setDisplayVehicleList] = useState(false);
  const toggleVehicleList = useCallback(async () => {
    return setDisplayVehicleList(!displayVehicleList);
  }, [displayVehicleList]);

  return (
    <VehicleListContext.Provider value={{ displayVehicleList, toggleVehicleList }}>
      {children}
    </VehicleListContext.Provider>
  );
};
