import { Body2, EhiButton, H6 } from '@ehi/ui';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { TimePickerField } from 'components/shared/forms/TimePickerField';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { FC, useMemo, useState } from 'react';
import { BranchV2 } from 'services/location/locationTypes';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { ArrowRightAlt } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { RentalLocationAddress } from './RentalLocationAddress';
import { combineDateAndTime, isInvalidDateTime, toDateTimeString } from 'utils/dateUtils';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateTime } from 'luxon';
import { WhenAndWhereFields } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { selectIsReadOnlyFlow, selectLengthOfRental, selectOneWay } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { BranchLookupModal } from './branchLookup/BranchLookupModal';

type RentalReturnSectionProps = {
  returnBranch: BranchV2 | undefined;
  returnDateTime: string | undefined;
  startDate: DateTime | undefined;
  handleRentalReturnLocation: (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string
  ) => void;
};

export const RentalReturnSection: FC<RentalReturnSectionProps> = ({
  returnBranch,
  returnDateTime,
  startDate,
  handleRentalReturnLocation,
}) => {
  const { t } = useTranslations();
  const { setValue, getValues, trigger, setError } = useFormContext();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const lengthOfRental = useAppSelector(selectLengthOfRental);
  const isOneWay = useAppSelector(selectOneWay);
  const [showReturnLocationModal, setShowReturnLocationModal] = useState(false);
  const [returnLocationTimezone] = useWatch({ name: [WhenAndWhereFields.ReturnLocationTimezone] });
  const formattedReturnDate = useMemo(
    () => toDateTimeString(returnDateTime, t('format.shortWeekdayDate'), returnBranch?.timezone),
    [returnBranch?.timezone, returnDateTime, t]
  );
  const formattedReturnTime = useMemo(
    () => toDateTimeString(returnDateTime, t('format.timeWithZone'), returnBranch?.timezone),
    [returnBranch?.timezone, returnDateTime, t]
  );

  const combineDateTime = (date: DateTime | '', time: DateTime | '') => {
    if (isInvalidDateTime(time)) {
      setError(WhenAndWhereFields.ReturnTime, { message: t('validation.invalidTimeFormat') });
    } else {
      const combined = !!date && !!time ? combineDateAndTime(date, time, returnBranch?.timezone) : '';
      setValue(WhenAndWhereFields.ReturnDateTime, combined);
    }
  };

  const handleDateChange = async (date: DateTime | '') => {
    setValue(WhenAndWhereFields.ReturnDate, date);
    await trigger([WhenAndWhereFields.StartDate, WhenAndWhereFields.ReturnTime]);

    const time = getValues(WhenAndWhereFields.ReturnTime);
    combineDateTime(date, time);
  };

  const handleTimeChange = async (time: DateTime | '') => {
    await trigger([WhenAndWhereFields.ReturnDate]);
    const date = getValues(WhenAndWhereFields.ReturnDate);
    setValue(WhenAndWhereFields.ReturnTime, time);
    combineDateTime(date, time);
  };

  return (
    <GridContainer data-testid={'rentalReturnSection'}>
      {isReadOnly ? (
        <GridItem xs={12} sm={12} md={12}>
          <Caption2 display={'block'}>
            {t('whenWhere.rentalReturn')}
            {isOneWay && (
              <IconButton data-testid='oneWayIcon' style={{ padding: 0 }}>
                <ArrowRightAlt fontSize='small' color='secondary' />
              </IconButton>
            )}
          </Caption2>
          <div data-testid='returnDate'>
            <Body2 bold display='inline' noWrap={true}>
              {formattedReturnDate}{' '}
            </Body2>
            <Body2 display='inline' noWrap={true}>
              {formattedReturnTime}
            </Body2>
            <Body2 display={'inline'} data-testid={'lengthOfRental'}>
              {` (${lengthOfRental}${lengthOfRental === 1 ? t('whenWhere.day') : t('whenWhere.days')})`}
            </Body2>
          </div>
          {returnBranch && <RentalLocationAddress branch={returnBranch} />}
        </GridItem>
      ) : (
        <>
          <GridItem xs={12} sm={12} md={12} data-testid={'rentalReturnLocation'}>
            <H6 data-testid={'rentalReturnHeader'}>{t('whenWhere.rentalReturn')}</H6>
            <Caption2 data-testid={'rentalReturnLocationLabel'}>
              {t('whenWhere.returnLocation')}
              {isOneWay && (
                <IconButton data-testid='oneWayIcon' style={{ padding: 0 }}>
                  <ArrowRightAlt fontSize='small' color='secondary' />
                </IconButton>
              )}
            </Caption2>
            <EhiButton data-testid={'rentalReturnLocationEdit'} onClick={() => setShowReturnLocationModal(true)}>
              {t('common.edit')}
            </EhiButton>
            {returnBranch && <RentalLocationAddress branch={returnBranch} />}
          </GridItem>
          <GridItem xs={12} sm={12} md={6} data-testid={'rentalReturnDatePicker'}>
            <DatePickerField
              disablePast
              disabled={!returnBranch}
              minDate={startDate}
              name={WhenAndWhereFields.ReturnDate}
              label={t('whenWhere.date')}
              submitOnChange={handleDateChange}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} data-testid={'rentalReturnTimePicker'}>
            <TimePickerField
              id={'rentalReturnTime'}
              disabled={!returnBranch}
              name={WhenAndWhereFields.ReturnTime}
              label={t('whenWhere.time')}
              submitOnChange={handleTimeChange}
              onSubmitTime={handleTimeChange}
              timezone={returnLocationTimezone}
            />
          </GridItem>
        </>
      )}

      {showReturnLocationModal && (
        <BranchLookupModal
          open={showReturnLocationModal}
          pickupOrDropOffLocation={WhenAndWhereFields.ReturnLocation}
          handleCancel={() => setShowReturnLocationModal(false)}
          title={t('whenWhere.branchLookup')}
          handleApply={(
            branchUrn: string,
            stationId: string,
            locationCurrentTime: DateTime | undefined,
            timezone?: string
          ) => {
            setShowReturnLocationModal(false);
            handleRentalReturnLocation(branchUrn, stationId, locationCurrentTime, timezone);
          }}
        />
      )}
    </GridContainer>
  );
};
