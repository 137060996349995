import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import Error from '@mui/icons-material/Error';
import { FormTextFieldProps } from 'components/shared/forms/FormFieldTypes';
import { useTranslations } from 'components/shared/i18n';

export const TimePickerField: FunctionComponent<
  Omit<FormTextFieldProps, 'onError' | 'rules'> & {
    submitOnChange?: (value: DateTime | '') => void;
    onSubmitTime?: (value: DateTime | '') => void;
    timezone?: string;
  }
> = ({ submitOnChange, onSubmitTime, timezone, disabled, ...rest }) => {
  const { t } = useTranslations();
  const form = useFormContext();

  const getHelperText = (errorMessage: string) => {
    return (
      <span style={{ display: 'flex' }}>
        <Error fontSize='small' sx={{ paddingRight: '6px' }} />
        {errorMessage}
      </span>
    );
  };

  return (
    <Controller
      control={form.control}
      {...rest}
      render={({ field, fieldState }) => {
        return (
          <DesktopTimePicker
            {...field}
            format={t('format.time')}
            value={field.value ? field.value : null}
            disabled={disabled}
            onAccept={async (value) => {
              form.setValue(field.name, value, { shouldDirty: true });
              if (await form.trigger(field.name)) {
                onSubmitTime?.(value as DateTime);
              }
            }}
            timezone={timezone}
            onChange={(time: DateTime | null) => {
              form.setValue(rest.name, time || null, {
                shouldDirty: true,
              });
            }}
            slots={{
              textField: TextField,
            }}
            slotProps={{
              openPickerIcon: {
                color: fieldState.error?.message ? 'error' : 'inherit',
              },
              inputAdornment: {
                sx: { svg: { paddingRight: '12px' } },
              },
              textField: {
                name: field.name,
                label: rest.label,
                required: rest.required,
                error: !!fieldState.error,
                helperText: !!fieldState.error?.message && getHelperText(fieldState.error?.message),
                onBlur: async () => {
                  if (await form.trigger(field.name)) {
                    onSubmitTime?.(form.getValues(field.name));
                  }
                },
                onChange: async (e: any) => {
                  let validated;
                  form.setValue(rest.name, (e as DateTime) || null, {
                    shouldDirty: true,
                  });

                  if (field.value) {
                    submitOnChange?.(form.getValues(field.name));
                    validated = await form.trigger(field.name);
                  } else {
                    form.setError(field.name, { message: t('validation.invalidTimeFormat') });
                  }
                  if (validated) {
                    submitOnChange?.(form.getValues(field.name));
                  }
                },
              },
            }}
          />
        );
      }}
    />
  );
};
