import { styled } from '@mui/material';
import { Body1, Body2, EhiButton, ehiTheme, Subtitle1 } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: ${ehiTheme.spacing(0.5, 0)};
  border-top: 1px solid #ccc;
`;

export const EhiButtonReset = styled(EhiButton)(() => ({
  width: '40px',
  height: '36px',
  fontsize: '14px',
}));

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${ehiTheme.spacing(1, 0)};
`;

export const HeaderTitle = styled(Subtitle1)`
  color: #000000de;
`;
HeaderTitle.defaultProps = {
  bold: true,
};

export const AllToggleText = styled(Body1)`
  color: #000000de;
`;

export const SubHeaderTitle = styled(Body2)`
  color: #0000008a;
  padding-top: ${ehiTheme.spacing(2)};
`;
SubHeaderTitle.defaultProps = {
  bold: true,
};

export const StyledFormTextField = styled(FormTextField)`
  width: 295px;
  @media (max-width: 800px) {
    width: 240px;
  }
`;

export const SubHeaderContainer = styled('div')`
  position: sticky;
  z-index: 1000;
  background-color: #ffffff;
`;
