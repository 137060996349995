import { Vehicle, Vehicles } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import { MouseEventHandler } from 'react';

export type RateCardType = {
  amount: number;
  currency: string;
  period: string;
};

export interface VehicleSectionProps {
  vehicles: Vehicles;
  isContentVisible?: boolean;
  onToggleContent?: MouseEventHandler<HTMLDivElement>;
}

export interface VehicleCardProps {
  vehicle: Vehicle;
  isSelected?: boolean;
}

export interface VehicleSearchValues {
  vehicleClass: string;
  vehicleType: string;
  fuelType: string;
  passengerNumber: string;
}

export enum VehicleSearchFields {
  VehicleClass = 'vehicleClass',
  VehicleType = 'vehicleType',
  FuelType = 'fuelType',
  PassengerNumber = 'passengerNumber',
}
