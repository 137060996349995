import { Grid, styled, Theme } from '@mui/material';
import { GridItem } from 'components/shared/ui/styles/Grid.styles';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { ehiTheme } from '@ehi/ui';

export const StyledDivider = styled(EhiDivider)(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const QuickResHeader = styled('h4')`
  margin-bottom: 0;
`;

export const SectionContainer = styled(Grid)`
  width: 100%;
  padding: ${ehiTheme.spacing(0, 1)};
`;
SectionContainer.defaultProps = {
  container: true,
};

export const SectionInfo = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding: ${ehiTheme.spacing(2)};
  background: #f5f5f5;
`;
SectionInfo.defaultProps = {
  container: true,
};

export const AccountNumberSearch = styled(Grid)`
  padding: ${ehiTheme.spacing(2, 0)};
`;
AccountNumberSearch.defaultProps = {
  container: true,
};

export const VehicleSearch = styled(GridItem)`
  padding: ${ehiTheme.spacing(2, 0)};
`;
VehicleSearch.defaultProps = {
  container: true,
};
