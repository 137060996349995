import { Box, Button, CardContent, Link, styled } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { PersonOutlined, WorkOutline } from '@mui/icons-material';

export const VehicleInfoContainer = styled(CardContent)`
  margin: ${ehiTheme.spacing(7, 1, 1, 1)};
`;

export const VehicleSearchContainer = styled(CardContent)`
  margin: ${ehiTheme.spacing(0, 0, -1, 0)};
`;

export const VehicleSearchHeader = styled('div')<{ isContentHidden?: boolean }>(({ isContentHidden = false }) => {
  return {
    marginBottom: !isContentHidden ? ehiTheme.spacing(-2.5) : ehiTheme.spacing(0),
    marginLeft: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
});
export const StyledResetButton = styled(Button)`
  padding: ${ehiTheme.spacing(2, 0, 2, 2)};
`;

export const VehicleListHeader = styled('div')`
    font-size: ${ehiTheme.typography.caption}
    background-color: ${ehiTheme.palette.background.default};
    padding: ${ehiTheme.spacing(3, 2, 0, 2)};
    color: ${ehiTheme.palette.secondary.main};
`;

export const VehicleListItem = styled('div')`
  background-color: ${ehiTheme.palette.background.default};
  padding: ${ehiTheme.spacing(1, 2)};
`;

export const CarClassText = styled('span')<{ selected: boolean }>((props) => ({
  fontSize: `${ehiTheme.typography.body1.fontSize}`,
  fontWeight: 'bold',
  padding: `${ehiTheme.spacing(2, 0)}`,
  color: props.selected ? 'white' : '#4d789d',
}));

export const VehicleDescriptionText = styled('span')<{ selected?: boolean }>((props) => ({
  fontSize: `${ehiTheme.typography.caption.fontSize}`,
  padding: `${ehiTheme.spacing(1, 0)}`,
  color: props.selected ? 'white' : '#0000008a',
}));

export const VehicleSpecTextContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  font-size: ${ehiTheme.typography.caption.fontSize};
`;

export const VehicleSpecText = styled('div')<{ selected: boolean }>((props) => ({
  padding: `${ehiTheme.spacing(0, 0, 0, 0.5)}`,
  color: props.selected ? 'white' : '#0000008a',
}));

export const VehicleTotalRateText = styled('div')`
  font-size: ${ehiTheme.typography.body1.fontSize};
  font-weight: bold;
  width: 100%;
  text-align: end;
`;

export const DistanceAllotmentText = styled('div')<{ selected: boolean }>((props) => ({
  color: props.selected ? 'white' : '#0000008a',
  textAlign: 'end',
  fontSize: `${ehiTheme.typography.caption.fontSize}`,
  fontWeight: 400,
}));

export const AdditionalVehicleInfoContainer = styled(Box)<{ selected?: boolean }>((props) => ({
  color: 'black',
  backgroundColor: props.selected ? '#4D789D14' : ehiTheme.palette.background.default,
  padding: ehiTheme.spacing(2),
}));

export const AdditionalInfoLabel = styled('h5')`
  margin: ${ehiTheme.spacing(0.5, 0)};
`;

export const StyledPassengerIcon = styled(PersonOutlined)<{ selected: boolean }>((props) => ({
  color: props.selected ? 'secondary' : 'gray',
  width: props.theme.spacing(2),
  height: props.theme.spacing(2),
}));

export const StyledLuggageIcon = styled(WorkOutline)<{ selected: boolean }>((props) => ({
  color: props.selected ? 'secondary' : 'gray',
  width: props.theme.spacing(2),
  height: props.theme.spacing(2),
}));

export const StyledVehicleLink = styled(Link)`
  color: #4d789d;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
  margin-left: 0;
  padding: 0;
`;
