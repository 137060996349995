import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { FC, useRef } from 'react';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { useTranslations } from 'components/shared/i18n';
import { DriverForm } from 'components/flexFlow/driver/driverForm/DriverForm';
import { EhiButton } from '@ehi/ui';

export type EditDriverDialogProps = {
  driver: DriverData | undefined;
  open: boolean;
  onClose: (driverModified: boolean) => void;
};

export const EditDriverDialog: FC<EditDriverDialogProps> = ({ open, onClose, driver }): JSX.Element => {
  const { t } = useTranslations();
  const formRef = useRef<{ handleSubmit: () => Promise<void> }>(null);

  const submitForm = async () => {
    if (formRef.current) {
      await formRef.current?.handleSubmit();
    }
  };

  const handleClose = (driverModified: boolean) => {
    onClose(driverModified);
  };

  return (
    <Dialog
      title={t('driver.editPrimaryDriver')}
      titleActions={[
        <EhiButton
          key={'changePrimaryDriver'}
          variant='text'
          onClick={() => window.alert('Change Primary Driver Clicked')}>
          {t('driver.changePrimaryDriver')}
        </EhiButton>,
      ]}
      open={open}
      contentPadding={0}
      a11yKey='content'
      showDividers
      maxWidth={'lg'}
      actions={{
        primaryAction: {
          label: t('common.update'),
          onClick: submitForm,
        },
        secondaryAction: {
          label: t('common.cancel'),
          onClick: () => onClose(false),
        },
      }}>
      <DriverForm onClose={handleClose} formRef={formRef} driver={driver} data-testId={'editDriverDialog'} />
    </Dialog>
  );
};
