import { Options } from 'context/queryClient/QueryTypes';

export const FIFTEEN_HOURS = 1000 * 60 * 60 * 15; // 15 hours in milliseconds
export const INFINITY = Infinity;
// Long enough to last for a res transaction. change it if needed to match editor session
export const THIRTY_MIN = 1000 * 60 * 30;

export const DOMAIN_CACHE_OPTIONS: Options = {
  staleTime: FIFTEEN_HOURS,
  cacheTime: FIFTEEN_HOURS,
};

export const SESSION_CACHE_OPTIONS: Options = {
  staleTime: INFINITY,
  cacheTime: INFINITY,
};

export const TRANSACTION_CACHE_OPTIONS: Options = {
  staleTime: THIRTY_MIN,
  cacheTime: THIRTY_MIN,
};

// use for queries that shouldn't be cached beyond the reservation session, will be manually cleared
export const RES_SCOPE_PREFIX = 'resScope';
