import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { EhiButton, H6 } from '@ehi/ui';
import {
  RateAndBillingLabel,
  RateAndBillingSectionBox,
  RateAndBillingText,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectPayers } from 'redux/selectors/bookingEditor';
import { useMemo, useState } from 'react';
import { EditBillToDialog } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialog';
import { useSelector } from 'react-redux';
import { BillingAccountPayer, EditorBusinessPayer } from 'services/booking/bookingTypes';
import { mask } from 'utils/maskUtils';
import { parseUrn, parseUrnItem } from 'utils/urnUtils';
import { EditBillToProvider } from 'context/editBillTo/EditBillToContext';

export const BillToSection = () => {
  const { t } = useTranslations();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const payer = useSelector(selectPayers);
  const [editBillToModal, setEditBillToModal] = useState(false);

  const billingAccount = useMemo(() => {
    const businessPayer: BillingAccountPayer | undefined = payer?.business?.find(
      (businessPayer: EditorBusinessPayer) => businessPayer.type === 'BILLING_ACCOUNT'
    ) as BillingAccountPayer;
    return businessPayer?.billingAccount;
  }, [payer?.business]);

  return (
    <RateAndBillingSectionBox data-testid='billToSection'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <H6>{t('rateAndBilling.billTo.pageTitle')}</H6>
        {!isReadOnly && <EhiButton onClick={() => setEditBillToModal(true)}>{t('common.edit')}</EhiButton>}
      </Box>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <RateAndBillingLabel>{t('rateAndBilling.accountNumber')}</RateAndBillingLabel>
          {billingAccount && <RateAndBillingText bold>{parseUrnItem(billingAccount, 'account')}</RateAndBillingText>}
        </Grid>
        <Grid item xs={6} sm={6} data-testid='billingNumber'>
          <RateAndBillingLabel>{t('rateAndBilling.billTo.billingNumber')}</RateAndBillingLabel>
          {billingAccount && <RateAndBillingText bold>{mask(parseUrn(billingAccount), 4)}</RateAndBillingText>}
        </Grid>
      </Grid>
      {editBillToModal && (
        <EditBillToProvider>
          <EditBillToDialog onClose={() => setEditBillToModal(false)} />
        </EditBillToProvider>
      )}
    </RateAndBillingSectionBox>
  );
};
