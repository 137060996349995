import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';

import { EHI_ACCEPT_PROTECTED_DATA, IF_MATCH, PROTECTED_DATA_VALUE } from 'services/headerConstants';
import {
  RetrieveTransactionalProfileResponseContent,
  TransactionalProfileRequestContent,
  TransactionalProfileResponseContent,
} from 'services/renter/transactionalProfile/transactionalProfileTypes';

const TRANSACTIONAL_PROFILE_BASE_PATH = '/people/transactionalProfile';

export const getTransactionalProfile = async (
  transactionalProfileUuid: string
): Promise<RetrieveTransactionalProfileResponseContent> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${TRANSACTIONAL_PROFILE_BASE_PATH}/${transactionalProfileUuid}`, {
    genericUrl: `${TRANSACTIONAL_PROFILE_BASE_PATH}/{uuid}`,
    headers: {
      [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
    },
  });

  return response.data;
};

export const createTransactionalProfile = async (
  transactionProfileRequest: TransactionalProfileRequestContent
): Promise<TransactionalProfileResponseContent> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.post(`${TRANSACTIONAL_PROFILE_BASE_PATH}`, transactionProfileRequest, {
    genericUrl: `${TRANSACTIONAL_PROFILE_BASE_PATH}`,
    headers: {
      [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
    },
  });

  return response.data;
};

export const updateTransactionalProfile = async (
  transactionalProfileUuid: string,
  request: TransactionalProfileRequestContent,
  lockContext: string
): Promise<TransactionalProfileResponseContent> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.put(`${TRANSACTIONAL_PROFILE_BASE_PATH}/${transactionalProfileUuid}`, request, {
    genericUrl: `${TRANSACTIONAL_PROFILE_BASE_PATH}/{transactionalProfileUuid}`,
    headers: {
      [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
      [IF_MATCH]: lockContext,
    },
  });

  return response.data;
};
