import { useFieldArray, useFormContext } from 'react-hook-form';
import { PeoListItem } from './PeoListItem';
import {
  EquipmentFormFields,
  EquipmentFormModel,
  EquipmentItemFields,
  EquipmentItemModel,
} from 'components/flexFlow/peo/peoTypes';
import { DISABLE_EQUIPMENT_QUANTITY_CODE } from 'utils/peoUtils';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { FC, useMemo } from 'react';

export type EquipmentListType = {
  equipmentValues: EquipmentItemModel[];
};

export const EquipmentList: FC<EquipmentListType> = ({ equipmentValues }: EquipmentListType) => {
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);

  const formContext = useFormContext<EquipmentFormModel>();
  const { fields, replace } = useFieldArray({ control: formContext.control, name: EquipmentFormFields.Items });

  useMemo(() => {
    replace(equipmentValues);
  }, [replace, equipmentValues]);

  return (
    <>
      {fields.map((field, index) => {
        const item = field[EquipmentItemFields.Item];
        const namePrefix = `${EquipmentFormFields.Items}[${index}]`;
        const quantity = isReadOnly && item.quantity > 1 ? ` (${item.quantity})` : '';
        const equipmentTitle = item.title + quantity;
        const disableQuantity = item?.code ? DISABLE_EQUIPMENT_QUANTITY_CODE.includes(item.code) : false;

        return (
          <PeoListItem
            key={field.id}
            index={index}
            title={equipmentTitle}
            selectability={item?.selectability ?? ''}
            code={item?.code ?? ''}
            rate={item?.rate}
            checkBoxFieldName={`${namePrefix}.${EquipmentItemFields.Checked}`}
            quantityName={`${namePrefix}.${EquipmentItemFields.Quantity}`}
            isQuantityDisabled={disableQuantity}
            showCountDropdown
          />
        );
      })}
    </>
  );
};
