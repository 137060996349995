import { FunctionComponent } from 'react';
import { useTranslations } from 'components/shared/i18n';
import DynamicHelmet from 'components/shared/helmet/DynamicHelmet';
import { Outlet } from 'react-router-dom';
import { ResActionsProvider } from 'context/resActions/ResActionsContext';
import { StyledCreateIconButton } from 'app/router/Router.styles';

export const CreateRouter: FunctionComponent = () => {
  const { t } = useTranslations();
  return (
    <ResActionsProvider label={t('common.create')} icon={<StyledCreateIconButton data-testid={'createRouterIcon'} />}>
      <DynamicHelmet title={t('common.createReservation')} />
      <Outlet />
    </ResActionsProvider>
  );
};
