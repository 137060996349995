import { useCallback } from 'react';
import { parseUrn } from 'utils/urnUtils';
import { Error002 } from 'components/shared/errors/ErrorCodes';
import { parseBranch } from 'services/location/locationRollupUtils';
import { Location, SetLocationServiceHook } from 'services/location/locationRollupTypes';
import { filterBranches } from 'utils/locationUtils';
import { fetchQuery } from 'context/queryClient/queryUtil';
import { DOMAIN_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import { QUERY_KEY_LOCATION } from 'services/location/locationCache';
import { fetchBranch } from 'services/location/locationQueries';
import { getMultipleBranchDetails } from './locationService';

export const useSetLocationService = (): SetLocationServiceHook => {
  const getLocation = useCallback(async (locationId: string): Promise<Location> => {
    // search for locations
    const branchDetails = await getMultipleBranchDetails([locationId.toUpperCase()]);

    const branches = await Promise.all(
      branchDetails.map((branch) => {
        return fetchBranch(parseUrn(branch.urn));
      })
    );
    const filteredBranches = filterBranches(branches);
    if (filteredBranches.length < 1) {
      return Promise.reject(new Error(Error002));
    }

    return { location: parseBranch(filteredBranches[0]) };
  }, []);

  const queryLocation = async (locationId: string) => {
    return fetchQuery({
      queryKey: [QUERY_KEY_LOCATION, locationId?.toUpperCase()],
      queryFn: () => getLocation(locationId),
      ...DOMAIN_CACHE_OPTIONS,
    });
  };

  return {
    getLocation: getLocation,
    queryLocation: queryLocation,
  };
};
