import { FC, useMemo } from 'react';
import { Body1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DO_NOT_RENT,
  DriverWarningData,
  RENT,
  RenterWarningFields,
  RenterWarningFieldsValues,
} from 'components/flexFlow/driver/driverSearch/renterWarning/RenterWarningTypes';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { TFunction } from 'i18next';
import { WarningBanner } from 'components/flexFlow/driver/driverSearch/renterWarning/WarningBanner';
import { Box } from '@mui/material';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { WarningOptionsRadioGroup } from 'components/flexFlow/driver/driverSearch/renterWarning/WarningOptionsRadioGroup';
import { DriverWarningDetails } from 'components/flexFlow/driver/driverSearch/renterWarning/DriverWarningDetails';
import { RentalHeaderH6 } from 'components/customerHeader/CustomerHeader.styles';
import { StyledRenterWarningWrapper } from 'components/flexFlow/driver/driverSearch/renterWarning/RenterWarningDialog.styles';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import {
  useGetRenterDepartmentTypes,
  useGetRenterWarningDepartments,
  useGetWarningReasons,
} from 'services/renter/renterReferenceQueries';
import { transformToDriverWarningData } from 'components/shared/uiModels/driver/driverTransformer';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';

export type RenterWarningActions = {
  searchResult: SearchResult;
  onCancel: () => void;
  onSubmit: () => Promise<void> | void;
};

export const RenterWarningDialog: FC<RenterWarningActions> = ({
  onCancel,
  onSubmit,
  searchResult: driverProfileData,
}) => {
  const { t } = useTranslations();

  const { data: warningDepartments, isFetching: isWarningDepartmentsLoading } = useGetRenterWarningDepartments();
  const { data: departmentTypes, isFetching: isDepartmentTypesLoading } = useGetRenterDepartmentTypes();
  const { data: warningReasons, isFetching: isWarningReasonsLoading } = useGetWarningReasons();

  const driverWarningData = useMemo(() => {
    return (
      driverProfileData &&
      departmentTypes &&
      warningDepartments &&
      warningReasons &&
      transformToDriverWarningData(driverProfileData, departmentTypes, warningDepartments, warningReasons, t)
    );
  }, [driverProfileData, departmentTypes, warningDepartments, warningReasons, t]);

  const isLoading = isWarningDepartmentsLoading || isDepartmentTypesLoading || isWarningReasonsLoading;

  const handleSubmit = async (values: RenterWarningFieldsValues) => {
    if (values.selectedOption === RENT) {
      return onSubmit();
    } else {
      onCancel();
    }
  };

  const initialValues: RenterWarningFieldsValues = {
    [RenterWarningFields.SelectedOption]: DO_NOT_RENT,
  };
  const formMethods = useForm<RenterWarningFieldsValues>({ defaultValues: initialValues });
  const onFormSubmit = formMethods.handleSubmit(handleSubmit);
  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={true}
        a11yKey={'renter-warning'}
        data-testid={'renterWarningDialog'}
        fullWidth
        maxWidth={'sm'}
        title={t('driver.warnings.renterWarningTitle')}
        showDividers
        contentPadding={0}
        actions={{
          primaryAction: { label: t('common.confirm'), onClick: onFormSubmit },
          secondaryAction: { label: t('common.cancel'), onClick: onCancel },
        }}>
        <DriverWarningContent t={t} driverWarningData={driverWarningData} />
        <ProgressOverlay inProgress={formMethods.formState.isSubmitting || isLoading} />
      </Dialog>
    </FormProvider>
  );
};

type DriverWarningContentProps = {
  t: TFunction;
  driverWarningData: DriverWarningData | undefined;
};

const DriverWarningContent: FC<DriverWarningContentProps> = ({ t, driverWarningData }) => {
  return (
    <>
      <WarningBanner warningMessage={driverWarningData?.warningMessage ?? ''} />
      <Box style={{ margin: '1.5rem' }} data-testid={'renterWarningDetails'}>
        <StyledRenterWarningWrapper>
          <StyledWarningIcon />
          <RentalHeaderH6 bold display='inline' fontFamily={'Roboto'}>
            {driverWarningData?.name}
          </RentalHeaderH6>
        </StyledRenterWarningWrapper>
        <DriverWarningDetails driverWarningData={driverWarningData} />
        <Body1 bold>{t('driver.warnings.proceedQuestion')}</Body1>
        <WarningOptionsRadioGroup name={RenterWarningFields.SelectedOption} />
      </Box>
    </>
  );
};
