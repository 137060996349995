export enum RateQualificationRowKeys {
  MUST_INCLUDE = 'rateAndBilling.rateQualifications.mustInclude',
  MUST_EXCLUDE = 'rateAndBilling.rateQualifications.mustExclude',
  REQUIRED_OVERNIGHT = 'rateAndBilling.rateQualifications.requiredOvernight',
}

export enum DayOfWeekFlag {
  YES = 'Y',
  NO = 'N',
}

export enum DayOfWeek {
  Sun = 'SUNDAY',
  Mon = 'MONDAY',
  Tue = 'TUESDAY',
  Wed = 'WEDNESDAY',
  Thu = 'THURSDAY',
  Fri = 'FRIDAY',
  Sat = 'SATURDAY',
}

export const HOURS = 'HOURS';
export const DAYS = 'DAYS';

export type RateQualificationColumn = {
  id: DayOfWeek;
  label: DayOfWeekFlag;
};

export type RateQualificationRows = Map<RateQualificationRowKeys, RateQualificationColumn[]>;
