export enum SearchByType {
  AccountNumber = 'accountNumber',
  AccountSearch = 'accountSearch',
}

export interface RateSourceValues {
  [RateSourceFields.SearchBy]: string;
  [RateSourceFields.AccountNumber]: string;
  [RateSourceFields.RateProduct]: string;
}

export enum RateSourceFields {
  SearchBy = 'searchBy',
  AccountNumber = 'accountNumber',
  RateProduct = 'rateProduct',
}
