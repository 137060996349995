import { FC, useEffect, useMemo, useState } from 'react';
import { Body2, EhiButton, ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { CountrySearchDropDown } from 'components/shared/forms/CountrySearchDropDown';
import { GroupDropDown } from 'components/shared/forms/GroupDropDown';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { piiField } from '@ehi/support';
import { useTranslations } from 'components/shared/i18n';
import { FormProvider, useForm } from 'react-hook-form';
import { PrimaryButton } from 'components/shared/ui/styles/Global.styles';
import { useLocationGroups, useLocationQuery } from 'services/location/locationQueries';
import { loadEhiLocationCookie } from '@ehi/location';
import {
  accountSearchValidationSchema,
  getAccountSearchValuesInitialValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/accountSearchUtils';
import {
  AccountSearchFields,
  AccountSearchValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearchTypes';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { getDefaultCountry } from 'utils/locationUtils';
import { AccountTypesDropDown } from 'components/shared/forms/AccountTypesDropDown';
import { useBusinessAccountSearch } from 'components/flexFlow/rateAndBilling/editDialogs/useBusinessAccountSearch';
import { Virtuoso } from 'react-virtuoso';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/editDialogs/BusinessAccountCard';
import { AccountListItem, StyledAccountsListBox } from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { transformAccountDetailsFromSearch } from 'utils/rateAndBillingUtils';
import { useMemoizedOptions, useMemoizedString } from 'hooks/useMemoizedOptions';
import { DAILY_RENTAL, getGroupOptions } from 'utils/branchLookupUtils';
import { useEditBillToContext } from 'context/editBillTo/EditBillToContext';

// Denotes the height of dialog footer and header along with the height of the account search section
export const ACCOUNT_LIST_OFFSET = 470;
// Denotes the percent offset of the dialog height. Currently, the dialog is set at 94%
export const DIALOG_HEIGHT_PERCENT_OFFSET = 0.06;

export type AccountSearchType = {
  handleSelect: (accountUrn: string) => void;
};

export const AccountSearch: FC<AccountSearchType> = ({ handleSelect }: AccountSearchType): JSX.Element => {
  const { t } = useTranslations();
  const cookie = loadEhiLocationCookie();
  const { previousSearchCriteria, previousSearchResults } = useEditBillToContext();
  const [scrollParent, setScrollParent] = useState<HTMLElement | undefined>(undefined);

  const { accounts, isFetchingAccounts, handleSearch, tooManyResults, noResults, handleReset } =
    useBusinessAccountSearch();
  const accountList = useMemo(
    () => (previousSearchResults ? previousSearchResults : accounts),
    [accounts, previousSearchResults]
  );

  const { string: defaultCountry, isLoading: isLocationFetching } = useMemoizedString(
    useLocationQuery(cookie?.peoplesoftId ?? ''),
    getDefaultCountry
  );

  const { options: groupOptions, isLoading: isLocationGroupsLoading } = useMemoizedOptions(
    useLocationGroups(defaultCountry, DAILY_RENTAL),
    getGroupOptions
  );

  const resolver = useYupValidationResolver(accountSearchValidationSchema(t));

  const initialValues = useMemo(
    () =>
      previousSearchCriteria
        ? previousSearchCriteria
        : getAccountSearchValuesInitialValues(defaultCountry, groupOptions),
    [defaultCountry, groupOptions, previousSearchCriteria]
  );

  const formMethods = useForm({
    resolver,
    defaultValues: initialValues,
  });

  useEffect(() => {
    setScrollParent((window.document.getElementById('scrolling-view-accounts') as HTMLElement) ?? undefined);
  }, []);

  useEffect(() => {
    formMethods.reset(initialValues);
  }, [formMethods, initialValues]);

  const countrySelect = formMethods.watch(AccountSearchFields.Country);
  const accountName = formMethods.watch(AccountSearchFields.AccountName);
  const phoneNumber = formMethods.watch(AccountSearchFields.PhoneNumber);

  const onSubmit = async (values: AccountSearchValues) => {
    handleSearch(values, groupOptions);
  };

  const AccountCountHeader = () => {
    return (
      <Body2 color='textSecondary' data-testid='resultsCount' margin={ehiTheme.spacing(2)}>{`${
        accountList?.length ?? 0
      } ${t('rateAndBilling.results')}`}</Body2>
    );
  };

  const AccountFooter = () => {
    return (
      <Caption2 display={'flex'} justifyContent={'center'} paddingBottom={ehiTheme.spacing(5)}>
        {t('rateAndBilling.noMoreItems')}
      </Caption2>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <Box padding={ehiTheme.spacing(2)} style={{ background: '#f5f5f5' }} data-testid='accountSearchContainer'>
        <GridContainer>
          <>
            <GridItem sm={6}>
              <CountrySearchDropDown defaultCountry={defaultCountry} name={AccountSearchFields.Country} required />
            </GridItem>
            <GridItem sm={6}>
              <GroupDropDown
                groupFormFieldName={AccountSearchFields.Groups}
                countryFormFieldName={AccountSearchFields.Country}
                required
              />
            </GridItem>
          </>
          <>
            <GridItem sm={6}>
              <MaxLengthTextField
                name={AccountSearchFields.AccountName}
                autoFocus
                type='text'
                label={t('rateAndBilling.accountName')}
                maxLength={60}
              />
            </GridItem>
            <GridItem sm={6}>
              <AccountTypesDropDown accountTypeValues={initialValues[AccountSearchFields.AccountTypes]} />
            </GridItem>
          </>
          <GridItem sm={6}>
            <PhoneTextField
              country={countrySelect}
              name={AccountSearchFields.PhoneNumber}
              label={t('rateAndBilling.accountPhone')}
              data-testid='phone-number-field'
              className={piiField}
            />
          </GridItem>
        </GridContainer>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <EhiButton
            type={'reset'}
            onClick={() => {
              formMethods.reset();
              handleReset();
            }}>
            {t('common.reset')}
          </EhiButton>
          <PrimaryButton
            data-testid='searchAccount'
            disabled={
              (!phoneNumber && (accountName as string)?.length < 3) ||
              (!accountName && (phoneNumber as string)?.length < 4)
            }
            onClick={formMethods.handleSubmit(onSubmit)}>
            {t('common.search')}
          </PrimaryButton>
        </Box>
      </Box>
      <StyledAccountsListBox
        data-testid='accountSearchResults'
        containerHeight={window.innerHeight - window.innerHeight * DIALOG_HEIGHT_PERCENT_OFFSET - ACCOUNT_LIST_OFFSET}
        noResultsView={noResults || tooManyResults}>
        {accountList && accountList?.length > 0 && (
          <Virtuoso
            customScrollParent={scrollParent}
            totalCount={accountList.length}
            components={{ Header: AccountCountHeader, Item: AccountListItem, Footer: AccountFooter }}
            itemContent={(index: number) => {
              return (
                <BusinessAccountCard
                  account={transformAccountDetailsFromSearch(accountList[index])}
                  showDivider
                  showSelect
                  handleSelect={(accountUrn: string) => handleSelect(accountUrn)}
                />
              );
            }}
          />
        )}
        {noResults && (
          <NoResultsView
            noResultsTitle='rateAndBilling.noAccountsFound'
            noResultsDescription='rateAndBilling.tryAnotherSearch'
            isBackgroundColor={false}
            pageTitle={true}
          />
        )}
        {tooManyResults && (
          <NoResultsView
            noResultsTitle='rateAndBilling.tooManyResults'
            noResultsDescription='rateAndBilling.refineSearch'
            isBackgroundColor={false}
            pageTitle={true}
          />
        )}
      </StyledAccountsListBox>
      <ProgressOverlay inProgress={isLocationFetching || isLocationGroupsLoading || isFetchingAccounts} />
    </FormProvider>
  );
};
