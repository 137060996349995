import { FunctionComponent } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Alert } from '@mui/material';
import { Error } from '@mui/icons-material';
import { ehiTheme } from '@ehi/ui';

interface RenterWarningProps {
  errorMessage: string;
  useShortError?: boolean;
}

export const ErrorBanner: FunctionComponent<RenterWarningProps> = ({ errorMessage, useShortError = false }) => {
  const { t } = useTranslations();

  return (
    <Alert
      data-testid={'errorBanner'}
      severity='error'
      sx={{
        border: `1px solid ${ehiTheme.palette.error.main}`,
        display: 'flex',
        alignItems: 'center',
      }}
      icon={<Error />}>
      <span style={{ fontWeight: 'bold' }}>
        {useShortError ? `${t('error.error')}: ` : `${t('error.errorMessage')}: `}
      </span>
      {t(errorMessage)}
    </Alert>
  );
};
