import { FC } from 'react';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { StyledDivider } from './QuickRes.styles';
import RentalPickup from 'components/quickRes/RentalPickup';
import RentalReturn from 'components/quickRes/RentalReturn';

const QuickResDateTime: FC = () => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={6}>
        <RentalPickup />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <RentalReturn />
      </GridItem>
      <GridItem xs={12} sm={12}>
        <StyledDivider />
      </GridItem>
    </GridContainer>
  );
};

export default QuickResDateTime;
