import { FC, useCallback, useEffect } from 'react';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { piiField } from '@ehi/support';
import { useTranslations } from 'components/shared/i18n';
import { DateTime } from 'luxon';
import { PrimaryButton } from 'components/shared/ui/styles/Global.styles';
import {
  DriverSearchFields,
  DriverSearchValues,
  SearchByType,
} from 'components/flexFlow/driver/driverSearch/DriverSearchTypes';
import * as Yup from 'yup';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { CorporateCountry } from 'utils/countryUtils';
import { SearchButtonWrapper, SearchContainer } from 'components/flexFlow/driver/driverSearch/DriverSearch.styles';
import { SelectField } from 'components/shared/forms/SelectField';
import { generateEnumMenuItems } from 'utils/formUtils';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { CountrySubDivisionSelectField } from 'components/shared/forms/CountrySubDivisionSelectField';
import { loadEhiLocationCookie } from '@ehi/location';
import { useBranchInfoByPeopleSoftIdQuery } from 'services/location/locationQueries';
import { extractCountryCode } from 'utils/locationUtils';

export type DriverSearchFormProps = {
  search?: (values: DriverSearchValues) => void;
  loading?: boolean;
};
export const DriverSearchForm: FC<DriverSearchFormProps> = ({ search, loading = false }: DriverSearchFormProps) => {
  const { t } = useTranslations();

  const generateSearchByTypeRequiredValidation = (searchByType: SearchByType, minLength = 1) => {
    return {
      is: (searchBy: string) => searchBy === searchByType,
      then: () =>
        Yup.string()
          .required(t('validation.requiredField'))
          .min(minLength, t('validation.minFieldLength', { fieldLength: 2 })),
    };
  };

  const validationSchema = Yup.object().shape(
    {
      lastName: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.RenterInformation, 2)
      ),
      firstName: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.RenterInformation, 2)
      ),
      phoneNumber: Yup.string().when([DriverSearchFields.SearchBy, DriverSearchFields.DOB], {
        is: (searchBy: string, dateOfBirth: DateTime | undefined) =>
          searchBy === SearchByType.RenterInformation && !dateOfBirth,
        then: () => Yup.string().required(t('validation.requiredField')).min(10, t('validation.phoneNumberValidation')),
        otherwise: () => Yup.string(),
      }),
      dateOfBirth: Yup.date().when([DriverSearchFields.SearchBy, DriverSearchFields.PhoneNumber], {
        is: (searchBy: string, phoneNumber: string) =>
          searchBy === SearchByType.RenterInformation && !phoneNumber?.length,
        then: () =>
          Yup.date().required(t('validation.requiredField')).nullable().typeError(t('validation.invalidDateFormat')),
        otherwise: () => Yup.date().nullable(),
      }),
      loyaltyNumber: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.LoyaltyNumber, 2)
      ),
      licenseNumber: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.DriversLicense, 2)
      ),
      country: Yup.string().when(
        DriverSearchFields.SearchBy,
        generateSearchByTypeRequiredValidation(SearchByType.DriversLicense)
      ),
      state: Yup.string().when([DriverSearchFields.SearchBy, DriverSearchFields.Country], {
        is: (searchBy: string, country: string) =>
          searchBy === SearchByType.DriversLicense &&
          (country === CorporateCountry.UnitedStates || country === CorporateCountry.Canada),
        then: () => Yup.string().required(t('validation.requiredField')),
        otherwise: () => Yup.string(),
      }),
    },
    [[DriverSearchFields.PhoneNumber, DriverSearchFields.DOB]]
  );

  const initialValues: DriverSearchValues = {
    searchBy: SearchByType.RenterInformation,
    loyaltyNumber: '',
    licenseNumber: '',
    country: '',
    state: '',
    lastName: '',
    firstName: '',
    dateOfBirth: undefined,
    phoneNumber: '',
  };
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm<DriverSearchValues>({ resolver: resolver, defaultValues: initialValues });

  const handleSubmit = useCallback(
    async (values: DriverSearchValues) => {
      return search?.(values);
    },
    [search]
  );

  const onFormSubmit = methods.handleSubmit(handleSubmit);

  return (
    <SearchContainer>
      <FormProvider {...methods}>
        <DriverSearchFormContainer loading={loading} onFormSubmit={onFormSubmit} />
      </FormProvider>
    </SearchContainer>
  );
};

type DriverSearchFormContainerProps = {
  loading?: boolean;
  onFormSubmit: () => void;
};

const DriverSearchFormContainer: FC<DriverSearchFormContainerProps> = ({ onFormSubmit, loading }) => {
  const { t } = useTranslations();
  const cookie = loadEhiLocationCookie();
  const { data: branchV2 } = useBranchInfoByPeopleSoftIdQuery(cookie?.peoplesoftId ?? '');
  const { setValue, reset, setFocus } = useFormContext();
  const [searchBy, country] = useWatch({ name: [DriverSearchFields.SearchBy, DriverSearchFields.Country] });
  useEffect(() => {
    if (searchBy === SearchByType.RenterInformation) {
      setFocus(DriverSearchFields.LastName);
    } else if (searchBy === SearchByType.LoyaltyNumber) {
      setFocus(DriverSearchFields.LoyaltyNumber);
    } else if (searchBy === SearchByType.DriversLicense) {
      setFocus(DriverSearchFields.LicenseNumber);
    }
  }, [searchBy, setFocus]);
  return (
    <GridContainer>
      <GridItem sm={6}>
        <SelectField
          name={DriverSearchFields.SearchBy}
          label={t('location.searchBy')}
          options={generateEnumMenuItems(SearchByType, t)}
          hasNoneOption={false}
          onChange={(event) => {
            reset();
            setValue(DriverSearchFields.SearchBy, event.target.value);
          }}
        />
      </GridItem>
      {searchBy === SearchByType.RenterInformation && (
        <>
          <GridItem sm={5} />
          <GridItem sm={5} data-testid={'driver-lastName'}>
            <MaxLengthTextField
              name={DriverSearchFields.LastName}
              type='text'
              label={t('driver.lastName')}
              required
              maxLength={60}
              inputProps={{
                minLength: 2,
              }}
            />
          </GridItem>
          <GridItem sm={5} data-testid={'driver-firstName'}>
            <MaxLengthTextField
              name={DriverSearchFields.FirstName}
              type='text'
              label={t('driver.firstName')}
              required
              maxLength={35}
              inputProps={{
                minLength: 2,
              }}
            />
          </GridItem>
          <GridItem sm={5} data-testid={'driver-dateOfBirth'}>
            <DatePickerField name={DriverSearchFields.DOB} label={t('driver.dateOfBirth')} className={piiField} />
          </GridItem>
          <GridItem sm={5} data-testid={'driver-phoneNumber'}>
            <PhoneTextField
              country={extractCountryCode(branchV2?.addresses)}
              name={DriverSearchFields.PhoneNumber}
              label={t('driver.phoneNumber')}
              className={piiField}
            />
          </GridItem>
        </>
      )}
      {searchBy === SearchByType.LoyaltyNumber && (
        <GridItem sm={6} data-testid={'driver-loyaltyNumber'}>
          <MaxLengthTextField
            type='text'
            name={DriverSearchFields.LoyaltyNumber}
            label={t('driver.loyaltyNumber')}
            maxLength={25}
            required
            className={piiField}
          />
        </GridItem>
      )}
      {searchBy === SearchByType.DriversLicense && (
        <>
          <GridItem xs={10} sm={5} data-testid={'license-number'}>
            <MaxLengthTextField
              name={DriverSearchFields.LicenseNumber}
              type='text'
              label={t('driver.licenseNumber')}
              required
              maxLength={50}
              className={piiField}
            />
          </GridItem>
          <GridItem sm={6} data-testid={'driver-country'}>
            <CountrySelectField
              name={DriverSearchFields.Country}
              required
              label={t('location.country')}
              onChange={(event) => {
                setValue(DriverSearchFields.Country, event.target.value);
                setValue(DriverSearchFields.State, '');
              }}
            />
          </GridItem>
          {country.length > 0 && (country === CorporateCountry.UnitedStates || country === CorporateCountry.Canada) && (
            <GridItem sm={5} data-testid={'driver-state'}>
              <CountrySubDivisionSelectField
                name={DriverSearchFields.State}
                required
                label={country === CorporateCountry.UnitedStates ? t('location.state') : t('location.province')}
                country={country}
                onChange={(event) => {
                  setValue(DriverSearchFields.State, event.target.value);
                }}
                className={piiField}
              />
            </GridItem>
          )}
        </>
      )}
      <GridItem sm style={{ padding: 0 }}>
        <SearchButtonWrapper>
          <PrimaryButton
            disabled={loading}
            data-testid='searchDriver'
            onClick={() => {
              onFormSubmit();
            }}>
            {t('common.search')}
          </PrimaryButton>
        </SearchButtonWrapper>
      </GridItem>
    </GridContainer>
  );
};
