import { DateTime } from 'luxon';

export const INVALID_DATE_TIME = 'Invalid DateTime';

export const isInvalidDateTime = (time: DateTime | string): boolean => {
  return time ? time.toString() === INVALID_DATE_TIME : false;
};

export type MixedDateTime = DateTime | Date | string | number;
export const YEAR_MONTH_DAY_FORMAT = 'yyyy-MM-dd';
/**
 * @returns DateTime.now().toISO(), includes timezone offset
 * @example '2017-04-22T20:47:05.335-04:00'
 */
export const nowIso = (): string => DateTime.now().toISO();

/**
 * @returns DateTime.now()
 */
export const now = (): DateTime => DateTime.now();

export const toIsoFormat = (dateTime?: string | DateTime, zone?: string): string | undefined | null => {
  return toDateTime(dateTime, zone)?.toISO();
};

export const toDateTime = (mixed?: MixedDateTime | null, zone?: string): DateTime | undefined => {
  if (!mixed) {
    return undefined;
  }
  let dateTime: DateTime;

  if (typeof mixed === 'string') {
    dateTime = DateTime.fromISO(mixed);
  } else if (DateTime.isDateTime(mixed)) {
    dateTime = mixed;
  } else if (mixed instanceof Date) {
    dateTime = DateTime.fromJSDate(mixed);
  } else {
    return undefined;
  }

  if (zone) {
    return dateTime.setZone(zone);
  }
  return dateTime;
};

export const toDateTimeString = (
  dateTime?: string | DateTime,
  format?: string,
  timezone?: string
): string | null | undefined => {
  const dateTimeObject = toDateTime(dateTime, timezone);
  if (format) {
    return dateTimeObject?.toFormat(format);
  } else {
    return dateTimeObject?.toISO({ suppressMilliseconds: true });
  }
};

const ONE_HOUR = 60 * 60 * 1000;
const ONE_DAY = 24 * ONE_HOUR;

export const getDaysDiff = (dateStr1: string | undefined, dateStr2: string | undefined) => {
  const date1 = new Date(dateStr1 || '0');
  const date2 = new Date(dateStr2 || '0');
  const diffInMs = date2.getTime() - date1.getTime();
  return Math.floor(diffInMs / ONE_DAY);
};

export const combineDateAndTime = (dateObj: DateTime, timeObj: DateTime, zone?: string): DateTime | undefined => {
  try {
    const date = {
      year: dateObj?.year,
      month: dateObj?.month,
      day: dateObj?.day,
    };
    const time = {
      hour: timeObj?.hour,
      minute: timeObj?.minute,
      second: timeObj?.second,
    };

    return DateTime.fromObject({ ...date, ...time }, { zone });
  } catch (e: any) {
    return undefined;
  }
};

// ToDo remove this.. as it is same as toDateTime
/**
 * Simple function to convert a mixed date to a DateTime.
 * Does not guarantee the timezone or locale of the return DateTime object.
 * Does not filter out invalid date-times.
 */
export const parseDateTime = (mixed: MixedDateTime | undefined): DateTime | undefined => {
  if (!mixed) {
    return undefined;
  }

  if (typeof mixed === 'string') {
    return DateTime.fromISO(mixed, { setZone: true });
  } else if (typeof mixed === 'number') {
    return DateTime.fromMillis(mixed);
  } else if (DateTime.isDateTime(mixed)) {
    return mixed;
  } else if (mixed instanceof Date) {
    return DateTime.fromJSDate(mixed);
  }

  return undefined;
};

export const compareDateTime = (a: MixedDateTime | undefined, b: MixedDateTime | undefined): boolean => {
  const a_ = parseDateTime(a);
  const b_ = parseDateTime(b);

  if (!a_?.isValid && !b_?.isValid) {
    return false;
  }
  if (!a_?.isValid) {
    return false;
  }
  if (!b_?.isValid) {
    return false;
  }

  return a_.toMillis() >= b_.toMillis();
};

export const compareDate = (a: DateTime | undefined, b: DateTime | undefined): boolean => {
  if (!a || !b) {
    return false;
  }
  const a_ = a.startOf('day');
  const b_ = b.startOf('day');
  if (a_.toLocaleString() === b_.toLocaleString()) {
    return true;
  } else {
    return a > b;
  }
};

export function calculateAge(dateStr: string) {
  const age = DateTime.fromFormat(dateStr, YEAR_MONTH_DAY_FORMAT).diffNow('years').years * -1;
  return Math['floor'](age);
}

export const datesAreSelected = (pickupDate: string | undefined, returnDate: string | undefined) => {
  return !(pickupDate?.valueOf() == undefined || returnDate === undefined);
};

export const currentDate = DateTime.now().toFormat(YEAR_MONTH_DAY_FORMAT);

export const convertTimeTo12HourFormat = (time: string) => {
  const [hourString, minute] = time.split(':');
  const hour = +hourString % 24;
  return `${hour % 12 || 12}:${minute} ${hour < 12 ? 'AM' : 'PM'}`;
};
