import { DateTime } from 'luxon';

export enum DriverFormFields {
  DriverType = 'driverType',

  // Driver Section
  LastName = 'lastName',
  FirstName = 'firstName',
  Age = 'age',
  DriverCountry = 'driverCountry',
  StreetAddress1 = 'streetAddress1',
  StreetAddress2 = 'streetAddress2',
  City = 'city',
  SubRegion = 'subRegion',
  PostalCode = 'postalCode',

  // License Section
  LicenseCountry = 'licenseCountry',
  LicenseNumber = 'licenseNumber',
  LicenseIssuerRegion = 'licenseIssuerRegion',
  LicenseIssuerAuth = 'licenseIssuerAuth',
  LicenseExpirationDate = 'licenseExpirationDate',
  IdentificationNumber = 'identificationNumber',
  LicenseDVLA = 'licenseDVLA',
  LicenseIssuedDate = 'licenseIssuedDate',
  DOB = 'dateOfBirth',
  PlaceOfBirth = 'placeOfBirth',
  IdIssuingCountry = 'idIssuingCountry',
  IdExpirationDate = 'idExpirationDate',
  IdType = 'idType',
  IdPassportNumber = 'idPassportNumber',

  // Contact Section
  Phone = 'phone',
  Email = 'email',
}

export enum DriverType {
  DRIVER_PROFILE,
  DRIVER_PROFILE_DNR,
  LOYALTY_DRIVER_PROFILE,
}

export enum PhoneFieldNames {
  Type = 'type',
  CountryCode = 'countryCode',
  Number = 'number',
  Priority = 'priority',
}

export interface PhoneFormModel {
  countryCode?: string;
  number?: string;
  type?: string;
  priority?: number;
}

export interface DriverFormValues {
  [DriverFormFields.DriverType]: DriverType | undefined;
  [DriverFormFields.LastName]: string;
  [DriverFormFields.FirstName]: string;
  [DriverFormFields.Age]: string;
  [DriverFormFields.DriverCountry]: string;
  [DriverFormFields.StreetAddress1]: string;
  [DriverFormFields.StreetAddress2]: string;
  [DriverFormFields.City]: string;
  [DriverFormFields.SubRegion]: string;
  [DriverFormFields.PostalCode]: string;
  [DriverFormFields.LicenseCountry]: string;
  [DriverFormFields.LicenseNumber]: string;
  [DriverFormFields.LicenseIssuerRegion]?: string;
  [DriverFormFields.LicenseIssuerAuth]?: string;
  [DriverFormFields.LicenseExpirationDate]?: DateTime | '';
  [DriverFormFields.IdentificationNumber]?: string;
  [DriverFormFields.LicenseDVLA]?: string;
  [DriverFormFields.LicenseIssuedDate]?: DateTime | '';
  [DriverFormFields.DOB]?: DateTime | '';
  [DriverFormFields.PlaceOfBirth]?: string;
  [DriverFormFields.IdIssuingCountry]: string;
  [DriverFormFields.IdExpirationDate]?: DateTime | '';
  [DriverFormFields.IdType]: string;
  [DriverFormFields.IdPassportNumber]?: string;
  [DriverFormFields.Phone]: PhoneFormModel[];
  [DriverFormFields.Email]: string;
}
