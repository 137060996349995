import { getCache, prefetch, setCache } from 'context/queryClient/queryUtil';
import { SESSION_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import { Version } from 'services/version/versionTypes';
import axios from 'axios';

const VERSION_QUERY_KEY = ['version'];

export const getVersion = async (): Promise<Version> => {
  const { data } = await axios.get<Version>('/version.json');
  return data;
};

export async function prefetchVersion(): Promise<void> {
  return prefetch({ queryKey: VERSION_QUERY_KEY, queryFn: getVersion, ...SESSION_CACHE_OPTIONS });
}

export function getVersionCache(): Version | undefined {
  return getCache<Version>(VERSION_QUERY_KEY);
}

export function setVersionCache(versionData: Version): void {
  setCache(VERSION_QUERY_KEY, versionData);
}
