import QuickResModal from 'components/quickRes/QuickResModal';
import { getNavigationCache } from 'app/router/navigationManager';
import { useState } from 'react';

export const QuickResIframePage = () => {
  const currentPort = window.location.port;

  const [navigation] = useState(getNavigationCache());
  console.log(navigation?.origin, 'origin');

  const handleQuickResOnSubmit = (data: any) => {
    window.parent.postMessage(data, `http://localhost:${currentPort}/dev`);
    window.parent.location.href = `http://localhost:${currentPort}/dev`;
  };

  const handleQuickResOnCancel = () => {
    window.parent.postMessage('Quick Res Cancelled', `http://localhost:${currentPort}/dev`);
    window.parent.location.href = `http://localhost:${currentPort}/dev`;
  };

  const handleQuickResOnError = () => {
    console.log('Log errors');
  };

  return (
    <QuickResModal
      open
      onSaveAndExit={handleQuickResOnSubmit}
      onSaveAndContinue={handleQuickResOnSubmit}
      onCancel={handleQuickResOnCancel}
      onError={handleQuickResOnError}
    />
  );
};
