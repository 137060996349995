import { FC, useCallback, useRef } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useTranslations } from 'components/shared/i18n';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useMediaQuery } from '@mui/system';
import { EMPTY_VALUE } from 'utils/constants';
import {
  RateSourceFields,
  SearchByType,
} from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialogTypes';
import { RateSourceAccountNumberSearch } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/RateSourceAccountNumberSearch';
import { AccountSearch } from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearch';
import { RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource } from 'services/booking/bookingService';
import { logError } from 'components/shared/logger/splunkLogger';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';

export const INITIAL_VALUES = {
  [RateSourceFields.SearchBy]: SearchByType.AccountNumber,
  [RateSourceFields.AccountNumber]: EMPTY_VALUE,
  [RateSourceFields.RateProduct]: EMPTY_VALUE,
};

type RateSourceDialogProps = {
  open: boolean;
  closeModal: () => void;
};

export const EditRateSourceDialog: FC<RateSourceDialogProps> = ({ open, closeModal }): JSX.Element => {
  const { t } = useTranslations();
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  const formMethods = useForm({
    defaultValues: INITIAL_VALUES,
  });
  const formRef = useRef<{ handleSubmit: () => Promise<void> }>(null);
  const { setValue, watch } = formMethods;
  const [searchBy] = watch([RateSourceFields.SearchBy]);
  const { showAlert } = useAlert();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const submitForm = async () => {
    if (formRef.current) {
      await formRef.current?.handleSubmit();
    }
  };

  const handleClosingModal = useCallback(() => {
    formMethods.reset();
    formMethods.clearErrors();
    closeModal();
  }, [formMethods, closeModal]);

  const handleLogError = useCallback((error: unknown, message: string) => {
    logError({ error, message: message });
  }, []);

  const handleSelect = useCallback(
    async (accountUrn: string) => {
      try {
        const rateSourceRequestBody: RateSource = {
          type: 'NEGOTIATED',
          account: accountUrn,
        };
        const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
        if (!errors) {
          handleClosingModal();
        } else {
          await showAlert({
            variant: 'error',
            description: errors?.[0]?.localizedMessage || '',
          });
        }
      } catch (error) {
        handleLogError(error, `Unable to set account to editor id ${bookingEditorId}`);
      }
    },
    [bookingEditorId, handleClosingModal, handleLogError, showAlert, updateAndRefresh]
  );

  return (
    <FormProvider {...formMethods}>
      <Dialog
        PaperProps={{
          sx: {
            height: '94%',
            width: isMobileOrTablet ? '94%' : '52%',
          },
        }}
        data-testid='rateSourceDialog'
        id='rateSourceDialog'
        contentPadding={0}
        open={open}
        title={t('rateAndBilling.rateSource')}
        a11yKey='content'
        fullScreen
        showDividers
        actions={{
          primaryAction: {
            label: t('common.cancel'),
            onClick: handleClosingModal,
          },
        }}>
        <RadioGroup row style={{ padding: ehiTheme.spacing(2) }}>
          <FormControlLabel
            name={RateSourceFields.SearchBy}
            checked={searchBy === SearchByType.AccountNumber}
            control={<Radio color='primary' />}
            label={t('rateAndBilling.accountNumber')}
            onClick={() => setValue(RateSourceFields.SearchBy, SearchByType.AccountNumber)}
          />
          <FormControlLabel
            name={RateSourceFields.SearchBy}
            checked={searchBy === SearchByType.AccountSearch}
            control={<Radio color='primary' />}
            label={t('rateAndBilling.accountSearch')}
            onClick={() => setValue(RateSourceFields.SearchBy, SearchByType.AccountSearch)}
          />
        </RadioGroup>
        {searchBy === SearchByType.AccountNumber ? (
          <RateSourceAccountNumberSearch
            submitForm={submitForm}
            handleCloseModal={handleClosingModal}
            formRef={formRef}
          />
        ) : (
          <AccountSearch handleSelect={handleSelect} />
        )}
      </Dialog>
    </FormProvider>
  );
};
