import { useCallback } from 'react';
import {
  clearPersistedEditorSessionData,
  clearPersistedReservationData,
} from 'components/shared/preprocessor/ReservationSessionHelper';
import { removeItem, WORKFLOW_ID_KEY } from 'utils/localStorageUtils';
import { useAppDispatch } from 'redux/hooks';
import { resetReservationState } from 'redux/slices/booking/bookingEditorSlice';
import { RES_STATUS_LEGACY_CODES, ReservationSessionHelperHook } from './reservationDataTypes';
import { useLocation } from 'react-router-dom';
import { useReservationStatusQuery } from 'services/rentalReference/rentalReferenceQueries';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { Query, useQueryClient } from '@tanstack/react-query';
import { RES_SCOPE_PREFIX } from 'context/queryClient/cacheOptions';

export const useReservationSessionHelper = (): ReservationSessionHelperHook => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { data: reservationStatuses } = useReservationStatusQuery();
  const queryClient = useQueryClient();

  const clearReservationSession = useCallback(() => {
    clearPersistedReservationData();
    dispatch(resetReservationState(undefined));
    removeItem(WORKFLOW_ID_KEY);
  }, [dispatch]);

  const clearEditorSession = useCallback(() => {
    clearPersistedEditorSessionData();
    dispatch(resetReservationState(undefined));
    removeItem(WORKFLOW_ID_KEY);
  }, [dispatch]);

  const clearSession = useCallback(() => {
    if (pathname.includes('view')) {
      clearPersistedReservationData();
    } else {
      clearPersistedEditorSessionData();
    }
    dispatch(resetReservationState(undefined));
    removeItem(WORKFLOW_ID_KEY);
    queryClient.removeQueries({
      predicate: (query: Query) => query.queryKey.includes(RES_SCOPE_PREFIX),
    });
  }, [dispatch, pathname, queryClient]);

  /**
   * if the reservation is in the following status/lifecycle then determines the transaction type for reservation flow
   * Modify Flow
   * Sold (Confirmed)
   * On-Request (Pending)
   * Incomplete
   *
   * View Flow
   * Cancelled
   * No Show
   * RA Attached (Checked Out)
   * @return {TransactionTypes | undefined}
   * @param lifeCycle
   * @param resStatus
   * **/
  const determineTransactionType = useCallback(
    (lifeCycleUrn: string, resStatusUrn: string) => {
      const status = reservationStatuses?.find((item) => lifeCycleUrn === item.lifecycle && resStatusUrn === item.urn);
      if (!status) {
        return undefined;
      } else if (
        RES_STATUS_LEGACY_CODES.PENDING === status?.legacyCode ||
        RES_STATUS_LEGACY_CODES.SOLD === status?.legacyCode ||
        RES_STATUS_LEGACY_CODES.CONFIRMED === status?.legacyCode ||
        RES_STATUS_LEGACY_CODES.INCOMPLETE === status?.legacyCode
      ) {
        return TransactionTypes.Modify;
      } else if (
        RES_STATUS_LEGACY_CODES.CANCELLED === status?.legacyCode ||
        RES_STATUS_LEGACY_CODES.NO_SHOW === status?.legacyCode ||
        RES_STATUS_LEGACY_CODES.CHECKED_OUT === status?.legacyCode
      ) {
        return TransactionTypes.View;
      }
    },
    [reservationStatuses]
  );

  return { clearReservationSession, clearEditorSession, clearSession, determineTransactionType };
};
