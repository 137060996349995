import { Caption } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Vehicle } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import { FC, useMemo } from 'react';

export type VehicleFeaturesType = {
  vehicle?: Vehicle;
};
export const VehicleFeatures: FC<VehicleFeaturesType> = ({ vehicle }: VehicleFeaturesType) => {
  const { t } = useTranslations();
  const getVehicleTranslatedVehicleFeatures = useMemo(() => {
    return vehicle?.translatedVehicleFeatures?.map((feature, index) => {
      if (!vehicle?.translatedVehicleFeatures) {
        return;
      }
      if (index < vehicle?.translatedVehicleFeatures.length - 1) {
        return <Caption key={feature} color={'#000000DE'}>{`${feature} • `}</Caption>;
      }

      return (
        <Caption key={feature} color={'#000000DE'}>
          {feature}
        </Caption>
      );
    });
  }, [vehicle?.translatedVehicleFeatures]);
  return (
    <>
      <Caption2 data-testid='vehicleFeaturesLabel' bold color={'#000000DE'}>
        {t('vehicle.vehicleFeatures')}
      </Caption2>
      <div data-testid='vehicleFeatures'>{vehicle ? getVehicleTranslatedVehicleFeatures : ''}</div>
    </>
  );
};
