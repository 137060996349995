import { EMPTY_VALUE } from 'utils/constants';

export enum SearchByType {
  AccountNumber = 'accountNumber',
  AccountSearch = 'accountSearch',
}

export enum BillToFormFields {
  SearchBy = 'searchBy',
  AccountNumber = 'accountNumber',
  BillingNumber = 'billingNumber',
  HiddenAccountNumber = 'hiddenAccountNumber',
}

export interface BillToFormValues {
  [BillToFormFields.SearchBy]: string;
  [BillToFormFields.AccountNumber]: string;
  [BillToFormFields.BillingNumber]: string;
  [BillToFormFields.HiddenAccountNumber]: string;
}

export const BILL_TO_INITIAL_VALUES: BillToFormValues = {
  [BillToFormFields.SearchBy]: SearchByType.AccountNumber,
  [BillToFormFields.AccountNumber]: EMPTY_VALUE,
  [BillToFormFields.BillingNumber]: EMPTY_VALUE,
  [BillToFormFields.HiddenAccountNumber]: EMPTY_VALUE,
};

export const enum BillingAccountType {
  BILLING_ACCOUNT = 'BILLING_ACCOUNT',
  BILL_TO = 'BILL_TO',
}
