import {
  FetchQueryOptions,
  QueryClient,
  QueryFilters,
  QueryKey,
  QueryObserverOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { DateTime } from 'luxon';
import {
  DOMAIN_CACHE_OPTIONS,
  RES_SCOPE_PREFIX,
  SESSION_CACHE_OPTIONS,
  TRANSACTION_CACHE_OPTIONS,
} from 'context/queryClient/cacheOptions';
import { getCurrentIso3Locale } from 'components/shared/i18n/locales';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { useLocale } from 'components/shared/i18n';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // For persist to work properly, we want to pass QueryClient a cacheTime value to override the default during hydration.
      ...SESSION_CACHE_OPTIONS,
      refetchOnWindowFocus: false,
      retry: false,
      // allows queries to resolve with offline cache if available
      networkMode: 'offlineFirst',
    },
  },
});

export function fetchQuery<T>(options: FetchQueryOptions<T>) {
  return queryClient.fetchQuery<T>(options);
}

export function getCache<T>(cacheKey: Array<string>): T | undefined {
  return queryClient.getQueryData<T>(cacheKey);
}

export function setCache<T>(cacheKey: Array<string>, data: T): T | undefined {
  return queryClient.setQueryData(cacheKey, data, { updatedAt: DateTime.now().toMillis() });
}

export function prefetch<T>(options: FetchQueryOptions<T>): Promise<void> {
  return queryClient.prefetchQuery<T>(options);
}

export function removeCache(filters?: QueryFilters) {
  queryClient.removeQueries(filters);
}
export function updateCacheOptions(cacheKey: Array<string>, options: QueryObserverOptions) {
  queryClient.setQueryDefaults(cacheKey, options);
}

export type OurQueryKey = string | (string | undefined)[];

export type OurQueryArgs<TQueryFnData> = Omit<UseQueryOptions<TQueryFnData, EhiErrors>, 'queryKey'> & {
  // override queryKey so that it's not optional, and must be a string or undefined
  queryKey: OurQueryKey;
};

function generateQueryKeyWithLocale(
  key: (string | undefined)[] | string | undefined | QueryKey,
  locale?: string
): QueryKey {
  const currentLanguage = locale || getCurrentIso3Locale();

  return !Array.isArray(key) ? [currentLanguage, key] : [currentLanguage, ...key];
}

/**
 * Caches domain data for 15 hrs"
 */

export function fetchDomainQuery<T>({ queryKey, queryFn, ...options }: FetchQueryOptions<T>) {
  return queryClient.fetchQuery({
    queryKey: generateQueryKeyWithLocale(queryKey),
    queryFn,
    ...DOMAIN_CACHE_OPTIONS,
    ...options,
  });
}

export const useDomainQuery = <TQueryFnData = unknown>({
  queryKey,
  queryFn,
  ...options
}: OurQueryArgs<TQueryFnData>): UseQueryResult<TQueryFnData, EhiErrors> => {
  const { servicesIso3Locale } = useLocale();

  return useQuery({
    queryKey: generateQueryKeyWithLocale(queryKey, servicesIso3Locale),
    queryFn,
    ...DOMAIN_CACHE_OPTIONS,
    ...options,
  });
};

/**
 * Ensures query data is saved only within ticket scope, will be cleared upon leaving session or starting new session
 */
export const useResScopeQuery = <TQueryFnData = unknown>(
  options: OurQueryArgs<TQueryFnData>
): UseQueryResult<TQueryFnData, EhiErrors> => {
  return useQuery(buildResScopeQueryOptions(options));
};

/**
 * Extracted for easier use in combination with useQueries
 */
export const buildResScopeQueryOptions = <TQueryFnData = unknown>({
  queryKey,
  queryFn,
  ...options
}: OurQueryArgs<TQueryFnData>): UseQueryOptions<TQueryFnData, EhiErrors> => {
  return {
    queryKey: generateQueryKeyWithLocale([RES_SCOPE_PREFIX, ...queryKey]),
    queryFn,
    ...options,
    // Make ticket cache options last so they cannot be overriden
    ...TRANSACTION_CACHE_OPTIONS,
  };
};
