import { InputAdornment, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { FieldErrorIcon } from 'components/shared/ui/FieldErrorIcon';
import { EhiTextFieldProps } from './FormFieldTypes';

export const EhiTextField: FunctionComponent<EhiTextFieldProps> = ({
  fieldRef,
  inputProps,
  InputLabelProps,
  ...props
}) => {
  const labelId = `${props.id || inputProps?.id || props.name}-label`;

  return (
    <TextField
      {...props}
      InputLabelProps={{ ...InputLabelProps, id: labelId }}
      // field ref allows react-hook-forms and mui work nicely together
      inputProps={{
        ...inputProps,
        ref: fieldRef,
        name: props.name,
        'aria-labelledby': labelId,
        'data-testid': labelId,
      }}
      FormHelperTextProps={{ role: 'status' }}
      InputProps={{
        ...props.InputProps,
        endAdornment: props.error ? (
          <>
            {props.InputProps?.endAdornment}
            <InputAdornment
              position='end'
              sx={
                props.select ? { marginLeft: 0, marginRight: 3, paddingRight: 1 } : { marginLeft: 0, paddingRight: 1 }
              }>
              <FieldErrorIcon />
            </InputAdornment>
          </>
        ) : (
          props.InputProps?.endAdornment
        ),
      }}
    />
  );
};
